.sidebar-container {
	display: flex;
	flex: 0 0 200px;

	.view-area-sidebar {
		border-right: 1px solid lightgrey;
		height: 100vh;
		width: 200px;
		position: absolute;
		z-index: 1000;

		.ant-menu {
			border-right: none !important;
		}
	}
}
