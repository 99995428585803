@use 'css' as *;

.oidc-provider-setup {
    margin-bottom: $size-16;

    .oidc-provider-selection-btn {
        margin-right: $size-8;
        width: 200px;

        img {
            vertical-align: baseline;
        }
    }

    .ant-btn[type="submit"] {
        margin-right: $size-16;
    }
}

.oidc-provider-setup-form {
    input {
        width: 320px;
    }

    a {
        line-height: $size-32;
    }

    .oidc-provider-clear-link {
        color: $red-5;
        height: $size-32;
    }

    .oidc-provider-delete-link {
        color: $red-5;
        height: $size-32;

        svg {
            margin-top: 6px;
            margin-bottom: 6px;
            width: 20px;
            height: 20px;
        }
    }
}

.oidc-errored-form {
    input {
        color: $red-6;
    }
}

.oidc-help-article-link {
    display: inline-flex;
    align-items: center;
    margin-bottom: $size-8;
    line-height: $size-24;

    img {
        margin-right: $size-8;
    }
}
