@use 'css/vars/size';
@use 'css/mixins';

.initiative-section {
	.section-header {
		@include mixins.text-semibold(20);
		display: block;
		overflow: hidden;
		padding-top: size.$size-32;
		padding-bottom: size.$size-8;

		.anticon {
			margin-right: size.$size-8;
		}

		.header-extra {
			float: right;
		}
	}

	&:first-child {
		.section-header {
			padding-top: 0 !important;
		}
	}

	.ant-table.ant-table-scroll-horizontal {
		.ant-table-body {
			overflow: auto hidden !important;
		}
	}
}
