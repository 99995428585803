@use 'css/vars/palette';
@use 'css/vars/size';

.setup-audit-trail-graph {
	.ant-card-body {
		padding-top: 0px;
	}

	.graph-title {
		color: palette.$gray-7;
	}

	.graph-segment {
		height: size.$size-16;
		padding: 0px;
		margin-inline-end: size.$size-2;
	}

	.graph-legend {
		.ant-tag {
			height: 12px;
			width: 6px;
			padding-inline: unset;
			margin-inline-end: size.$size-2;
		}
	}
}
