@use 'css' as *;

.active-indicator {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	margin: 0.35em;
	width: auto;

	.active-indicator-inner {
		background-color: $red-7;
		border-radius: 6px;
		height: 12px;
		width: 12px;

		&.active {
			background-color: $green-7;
		}
	}
}
