@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.count-tag {
	@include mixins.text-regular(12);
	align-content: center;
	align-items: center;
	background: palette.$gray-1;
	border: 1px solid palette.$gray-5;
	border-radius: size.$size-4;
	display: inline-flex;
	justify-content: center;
	max-height: 20px;
	padding: size.$size-4;
	user-select: none;

	+ .count-tag {
		margin-left: size.$size-4;
	}

	.count {
		margin-left: size.$size-2;
	}
}
