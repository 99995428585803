@use 'css' as *;

.bulk-tag {
	padding: .75em;
	overflow-y: auto;

	.ant-space {
		width: 100%;
	}

	.field-select {
		width: 100%;
	}

	.field-name-tag {
		margin-right: $size-4;
	}
}
