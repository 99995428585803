@use 'css/vars/palette';
@use 'css/vars/size';

.access-changes-table {
	.message {
		display: flex;
		column-gap: size.$size-8;
		align-items: center;
		justify-content: space-between;
		line-height: 1em;

		.sonar-text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: fit-content;
			line-height: 1em;
		}

		.message-tags {
			display: flex;
			column-gap: size.$size-8;
			align-items: center;

			.hidden-text {
				font-style: italic;
			}
		}
	}

	.show-hidden {
		font-weight: 400;
	}

	.change-type-select {
		width: 140px;
	}

	.table-filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px size.$size-24 size.$size-16 size.$size-24;
		border-bottom: 1px solid palette.$gray-4;
	}

	.ant-card-head {
		border-bottom: none;
	}

	.ant-table-header {
		overflow: visible !important;
	}

	.ant-table .ant-table-thead > tr > th {
		padding: size.$size-8;
	}

	.ant-table-row.hidden {
		color: palette.$gray-7;
		filter: grayscale(0.85);

		.sonar-text {
			color: palette.$gray-7;
			line-height: 1em;
		}
	}

	.change-type-select .ant-select-selection-item {
		line-height: 20px;
	}
}
