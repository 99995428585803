.powered-by-sonar {
    svg {
        height: auto;
        width: 56px;
    }

    span:first-child {
        font-size: 10px;
        display: block;
    }
}
