.loading-submarine {
    width: 175px;
    margin: -20px auto;

    .bubbles {
        width: 30px;
        position: relative;
        top: 58px;
        left: 110px;
        animation: float 3s ease-in-out .2s infinite alternate;

        @keyframes float {
            from {
                transform: translateX(-12px);
            }
      
            to {
                transform: translateX(12px);
            }
          }
    }

    .submarine {
        animation: float 3s ease-in-out .2s infinite alternate;

        @keyframes float {
            from {
                transform: translateX(-12px);
            }
    
            to {
                transform: translateX(12px);
            }
        }
    }
}