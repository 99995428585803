@use 'css/vars/colors';
@use 'css/vars/size';

.gear-link-section {
	display: flex;
	column-gap: size.$size-8;
	align-items: center;
	width: 100%;

	.anticon-setting {
		font-size: size.$size-16;
		color: colors.$default-black;
	}
}
