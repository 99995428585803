@use 'css/vars/palette';

.quick-access-view-button {
	width: 100%;

	.view-name,
	.view-type {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
