@use 'css' as *;

.periscope-paginated-list {
	&.periscope-paginated-list-striped {
		.ant-spin-container {
			> div:nth-child(odd) {
				background-color: $gray-1;
			}

			> div:nth-child(even) {
				background-color: $gray-2;
			}
		}
	}

	.ant-empty {
		padding-top: $size-8;
		padding-bottom: $size-8;
		margin-left: $size-4;
		margin-right: $size-4;
	}
}
