.popover-menu-button-overlay {
    padding-top: 0;
    
    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner-content {
        padding: 0;
    }
}
