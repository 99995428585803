@use 'css/vars/colors';

#app {
	display: flex;
	width: initial;
	height: 100vh;
	background-color: colors.$app-background;

	> .app-content {
		display: flex;
		overflow: auto;
	}
}
