@use 'css' as *;

.comment-actions {
    .delete-button {
		color: $gray-6;

		:hover {
			color: $red-6;
		}
	}

	.edit-button {
		color: $gray-6;

		:hover {
			color: $blue-6;
		}
	}
}
