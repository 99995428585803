@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.sonar-tag {
	@include mixins.text-regular(12);
	display: inline-flex;
	align-items: center;

	.ant-tag {
		height: 20px;
		margin: 0px;
		cursor: pointer;
		padding: 0px size.$size-4;
		border: 1px solid palette.$gray-5;
		box-shadow: 0px 1px 1px palette.$gray-5;
	}

	.color-block {
		display: inline-flex;
		background-color: palette.$gray-8;
		width: 10px;
		height: 10px;
		border-radius: size.$size-4;
		margin-right: size.$size-4;
	}

	&.no-border {
		align-items: center;

		.color-block {
			width: 11px;
			height: 11px;
			border: solid 1px rgba(palette.$gray-1, 0.25);
			margin-left: size.$size-4;
		}
	}

	.tag-description {
		padding-left: size.$size-8;
	}
}

.linked-tag {
	.no-border {
		.color-block {
			border: solid 1px palette.$gray-8;
		}
	}
}
