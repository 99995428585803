@use 'css/vars/palette';
@use 'css/vars/size';

.view-selector {
	.ant-select-selection-item > .view-label {
		gap: 8px !important;

		> :nth-last-child(-n + 3):not(:only-child) {
			display: none !important;
		}
	}
}

.view-selector-dropdown {
	.ant-select-item-option:first-child {
		padding: 5px 12px;
	}

	.ant-select-item-option:not(:first-child) {
		padding: 0;
	}

	.quick-add-button-wrapper {
		border-top-color: palette.$gray-4;
		border-top-style: solid;
		border-top-width: 1px;
		margin-top: size.$size-4;
		padding-top: size.$size-4;
		padding-bottom: size.$size-2;
	}
}
