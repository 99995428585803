@use 'css' as *;

.compliance-categories {
    align-items: flex-start;
    display: flex;

    &.vertical {
        flex-direction: column;
    }

    &.horizontal {
        flex-direction: row;
        gap: $size-8;
    }
}
