@use 'css/vars/size';

.filter-by-name {
	margin: size.$size-8 0;
	width: 256px;
}

.shared-data-dictionary-table {
	height: calc(100vh - 96px);

	.ant-table {
		background-color: transparent;

		.ant-table-body {
			height: inherit;
		}
	}

	.source-link {
		visibility: hidden !important;
	}

	.periscope-text-editor {
		cursor: unset !important;

		&:hover {
			border-color: transparent !important;
		}
	}

	.plaintext-cell-editor {
		.plaintext-editor-wrapper {
			border-color: transparent !important;
			cursor: unset !important;
		}
	}
}
