@use './palette' as *;

$default-black: $gray-10;
$app-background: #e3e8ee;
$gateway-background: #15253e;
$sidebar-accent: #002140;
$sidebar-background: #001529;

$pardot-blue: #0D6FD9;
$salesforce-blue: #1890FF;
$sonar-blue: #1B96FF;

$addition: $green-7;
$modification: $cyan-7;
$deletion: $red-7;
$unmodified: $gray-6;
$lightGreen: $green-6;
