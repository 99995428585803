@use 'css/vars/palette';
@use 'css/vars/size';

.sort-by-filter {
	color: palette.$gray-9;
	float: right;

	.periscope-sort-by-text {
		display: inline-block;

		.anticon {
			margin-left: size.$size-4;
		}
	}

	.sort-icon {
		display: inline-block;
		padding: 0 size.$size-8;
	}
}

.sort-by-filter-entry {
	> span {
		flex: unset !important;
	}
}
