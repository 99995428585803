@use 'css/vars/palette';
@use 'css/vars/size';

.access-log-table {
	.table-filters {
		display: flex;
		align-items: center;
		column-gap: size.$size-8;
		padding: 0px size.$size-24 size.$size-16 size.$size-24;
		border-bottom: 1px solid palette.$gray-4;

		.ant-select,
		.field-name-input,
		.parent-object-input {
			width: 200px;
		}

		.compliance-categories .anticon img {
			height: 24px;
		}
	}

	.event-identifier-column,
	.classification-column {
		max-width: fit-content !important;
	}

	.field-column {
		display: flex;
		column-gap: size.$size-8;
		align-items: end;
		justify-content: space-between;

		.sonar-text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: fit-content;
		}

		.message-tags {
			display: flex;
			column-gap: size.$size-8;
			align-items: end;

			.hidden-text {
				font-style: italic;
			}
		}
	}

	.ant-card-head {
		border-bottom: none;
	}

	.ant-table .ant-table-thead>tr>th {
		padding: size.$size-8;
	}
}
