@use 'sass:list';
@use 'css/mixins';
@use 'css/vars/text';

@for $i from 1 through list.length(text.$textSizes) {
	$size: list.nth(text.$textSizes, $i);
	$lineHeight: list.nth(text.$textHeights, $i);

	.text-#{$size} {
        @include mixins.text-regular($size, $lineHeight);
	}

	.text-#{$size}-semibold {
        @include mixins.text-semibold($size, $lineHeight);
	}
}

.h1 { @extend .text-24; }
.h2 { @extend .text-20-semibold; }
.h3 { @extend .text-16-semibold; }
