@use 'css/vars/size';

.data-dictionary-search {
		display: flex;
		flex-wrap: nowrap;

		.data-dictionary-select {
			width: size.$input-width;
		}

		.compliance-categories .anticon > * {
			height: size.$size-24;
		}
}
