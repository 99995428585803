@use 'css' as *;

.scope-item {
	.delete-button {
		transition: none;
		visibility: hidden;
		height: $size-24;
		padding-left: $size-16;
		padding-right: 0px;
		line-height: 1;

		.ant-btn-sm {
			padding: 0px;
		}
	}

	&:hover {
		.delete-button {
			display: block;
			visibility: visible;
		}
	}
}
