@use 'css' as *;

.sonar-text {
	font-family: $default-font;
	color: $default-black;

	&.secondary {
		color: $gray-8;
	}
}
