@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.periscope-title {
	align-items: center;
	background:  palette.$gray-1;
	color: colors.$default-black;
	display: inline-flex;
	justify-content: center;
	margin-bottom: 0;
	padding: size.$size-16 size.$size-16 size.$size-8 size.$size-16;
	position: relative;

	.ant-row {
		align-items: center;
		margin-bottom: size.$size-16;
		width: 100%;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.api-name {
		@include mixins.text-regular(16);
		color: palette.$gray-6;

		.api-suffix,
		.raw-type-label {
			color: colors.$default-black;
		}
	}

	.tag-row {
		justify-content: flex-end;
	}

	.periscope-title-name {
		@include mixins.text-regular(24);
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: size.$size-4;
		text-wrap: nowrap;
	}

	.version {
		@include mixins.text-regular(24);
		padding-left: size.$size-16;
	}
}
