@use 'css/vars/palette';

.app-user-cell-editor {
	height: 100%;

	.ant-select {
		width: 100%;

		.ant-select-arrow,
		.ant-select-clear {
			margin-right: -12px;
		}

		.ant-select-selector {
			height: 100%;
			margin: 0px;
			padding: 0px;
			border: none !important;
			background: none !important;
			box-shadow: none !important;

			.ant-select-selection-search {
				left: 0;
				right: 0;
			}

			.ant-select-selection-item {
				padding-inline-end: 12px;
			}
		}
	}
}
