@use 'css/vars/size';

.detected-app-notice {
	padding: 0 size.$size-16;

	.sub-header {
		padding-top: size.$size-24;
		font-weight: 700;
	}

	ul {
		margin: size.$size-4 0;
	}
}
