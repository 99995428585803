@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.sync-entry {
    .ant-card-body {
        align-items: center;
        display: flex;

        .ant-collapse {
            width: 100%;
        }

        > .anticon,
        .empty-spinner {
            margin-left: size.$size-8;
        }

        > .anticon {
            @include mixins.text-regular(20);

            &.anticon-check-circle {
                color: palette.$green-7;
            }
            &.anticon-stop {
                color: palette.$red-6;
            }
        }
    }

    .ant-row {
        margin-bottom: size.$size-24;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}
