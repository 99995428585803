@use 'css/vars/size';

.confirm-delete-wrapper {
    display: inline-block;
    overflow: visible;
}

.confirm-delete-popup {
    width: max-content;
    max-width: size.$input-width;

    &:not(.ant-popover-hidden) {
        display: initial;
        visibility: initial;
    }
}
