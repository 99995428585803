@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.admin-customer-view-card {
	.ant-card-head {
		background-color: palette.$gray-2;
	}

	.ant-card-body {
		background-color: palette.$gray-3;
	}

	.ant-collapse {
		border: none;
		background: transparent;
	}

	.ant-collapse-item {
		border: none;
	}

	.ant-collapse-item + .ant-collapse-item {
		margin-top: size.$size-24;
	}

	.ant-collapse-header {
		background-color: palette.$gray-1;
	}

	.ant-collapse-content {
		border: none;

		table {
			border: 1px solid palette.$gray-4;
			border-radius: size.$size-4;
		}
	}

	.ant-collapse-content-box {
		padding-top: 0px;
	}

	.ant-input-search-button {
		width: size.$size-32;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.customer-view-title {
		display: flex;
		align-items: center;
	}

	.customer-view-extra {
		display: flex;
		align-items: center;
	}

	.customer-view-title-text {
		@include mixins.text-regular(24);
	}

	.customer-view-extra-metric {
		@include mixins.text-regular(16);
		margin-left: size.$size-24;
	}

	.customer-view-extra-dropdown-btn {
		background: transparent;
		box-shadow: none;
		border: none;
		width: size.$size-32;
		height: size.$size-32;
		margin-left: size.$size-16;
		display: flex;
		justify-content: center;

		svg {
			width: size.$size-24;
			height: size.$size-24;
		}
	}
}

.rename-customer-form {
	.ant-form-item {
		width: 100%;
	}
}
