@use 'css/vars/palette';
@use 'css/vars/size';

.sonar-record {
	border: 1px solid palette.$gray-5;
	box-shadow: inset size.$size-4 0 0 0 transparent;
	border-radius: size.$size-4;
	margin: size.$size-2 size.$size-4;

	> .ant-card-body {
		padding: size.$size-8 size.$size-16;

		> :not(:last-child) {
			padding-bottom: size.$size-8;
		}
	}

	&.clickable {
		&:hover {
			box-shadow: inset size.$size-4 0 0 0 palette.$blue-6;
			border: 1px solid palette.$gray-7;
		}
	}

	.ant-typography {
		strong {
			line-height: 22px;
		}
	}
}
