.data-dictionary-label {
	&:hover .show-more-link {
		opacity: 1;
	}

	.show-more-link {
		cursor: pointer;
		float: right;
		opacity: 0.5;
	}
}
