@use 'css/vars/palette';
@use 'css/vars/size';

.access-statistics-section {
	display: flex;
	flex-direction: column;
	row-gap: size.$size-4;
	width: 100%;

	.statistics-rows {
		display: flex;
		flex-direction: column;
		row-gap: size.$size-4;
		flex-grow: 1;
	}

	.top-five-title {
		color: palette.$gray-7;
	}

	.column-headers {
		display: flex;
		justify-content: space-between;
	}
}
