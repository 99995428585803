@use 'css/mixins';

#initiatives-media {
	.ant-collapse > .ant-collapse-item > .ant-collapse-header {
		padding: 0px;
	}

	.media-list {
		.ant-collapse-content-box {
			padding: 0px;
			max-height: 300px;
			overflow: auto;
		}

		a {
			@include mixins.text-regular(14);
		}
	}
}

.jira-issue-popover {
	background-color: transparent;

	&.ant-popover-placement-bottom,
	&.ant-popover-placement-bottomLeft,
	&.ant-popover-placement-bottomRight {
		padding-top: 0;
	}

	.ant-popover-arrow {
		display: none;
	}

	.ant-select-dropdown {
		width: 495px !important;
		left: 0 !important;
		min-width: 495px !important;
	}

	.ant-popover-inner-content {
		padding: 0;

		.jira-issue-select {
			margin: 12px 16px;
			width: 463px;
		}
	}
}
