@use 'css/vars/colors';
@use 'css/vars/palette';

.statistic-delta {
	&.positive {
		color: colors.$addition;
	}

	&.negative {
		color: colors.$deletion;
	}
}
