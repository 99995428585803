@use 'css/vars/size';

.jira-status-mapping {
	.ant-row {
		width: size.$input-width;

	}

	+ .jira-status-mapping {
		margin-top: size.$size-8;
	}

	.ant-select {
		width: 100%;
	}
}
