@use 'css' as *;

.new-password-validation {
    .ant-checkbox-wrapper,
    .ant-checkbox {
        cursor: unset;

        input {
            cursor: unset;
        }
    }

    .valid {
        color: $blue-6;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}
