@use 'css' as *;

.customer-settings-manager {
    margin-bottom: $size-24;

    .ant-form + .ant-form {
        margin-top: $size-8;
    }

    .ant-form-item-label {
        width: 225px;
    }

    input, .ant-input-number {
        width: 320px;
    }
}
