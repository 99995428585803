@use 'css/vars/palette';
@use 'css/vars/size';

#setup-audit-trail-table {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.table-modifier {
		padding: size.$size-8 0px;
	}

	.paginated-table .ant-table {
		background-color: palette.$gray-1;
	}
}
