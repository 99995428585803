@use 'css/vars/palette';
@use 'css/vars/size';

.scope-display-selector {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: size.$size-8;

    > span {
        color: palette.$gray-7;
    }

    > .ant-radio-group {
        display: flex;
        flex-direction: row;

        > * {
            height: 32px;
            width: 32px;
            padding: size.$size-4;
        }
    }
}
