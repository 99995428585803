@use 'css' as *;

.error-boundary {
	padding: $size-16;

	.error-info {
		color: $red-8;
	}
}

