.import-frequency-modal {
	.ant-modal-body {
		min-width: 400px;
	}

    .ant-form-item-no-colon {
        width: 100px;
    }
    
    .ant-select {
        width: 200px;
    }
}

.modal-dropdown-list {
    z-index: 1000;
}