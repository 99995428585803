@use 'css' as *;

.data-dictionary-columns-modal {
	.ant-modal-body {
		padding: 0px;
		max-height: 70vh;
    	overflow: auto;
	}

	.column-card {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 16px;
		background: $gray-1;
		border-bottom: 1px solid $gray-4;
		user-select: none;

		&:first-child {
			cursor: not-allowed;
		}

		&.dragging,
		&:hover {
			background-color: $gray-3;
		}

		&.drop {
			border-top: 1px solid $gray-4;
			margin-top: -1px;
		}

		&.hidden {
			color: $gray-6;
		}

		.sonar-text {
			color: inherit;
		}
	}

	.hide-column-toggle {
		cursor: pointer;
	}
}
