@use 'css/vars/size';

.monitor-details {
	>.ant-space-item {
		width: 100%;
	}

	&.margin {
		padding-top: size.$size-16;
	}

	.monitor-details-name {
		padding: size.$size-8 0px;
		margin-bottom: 0;

		input {
			width: size.$input-width;
		}

		label {
			font-weight: 600;
		}
	}
}
