@use 'css' as *;

.edit-avatar {
	display: flex;
	flex-direction: column;
	margin-right: $size-24;
}

.edit-avatar-drop-area {
	height: 240px !important;

	.upload-icon {
		color: $blue-5;
		font-size: 36px;
	}
}
