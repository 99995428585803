@use 'css/vars/palette';
@use 'css/mixins';

.scroll-shadow {
	width: 100%;
	height: fit-content;
	box-shadow: 0 1px 0 0 palette.$gray-5;
	transition: box-shadow 0.3s ease-in-out;
	z-index: 1000;

	&.drop-shadow {
		@include mixins.elevated();
	}
}
