@use 'css' as *;

.org-switcher-menu,
#org-switcher-menu-org-submenu-popup {
	background: $sidebar-accent !important;
	user-select: none;

	.ant-menu-item {
		> .ant-menu-title-content {
			display: flex;
		}
	}

	.ant-menu-submenu-title {
		color: $blue-5;
	}

	.selected-org {
		color: $blue-5;
	}

	.ant-menu-item-selected {
		background-color: $sidebar-accent;
	}

	.switcher-org-name {
		display: inline-block;
		line-height: normal;
		margin-left: 0 !important;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.org-entry-wrapper {
		line-height: 1;
		justify-content: space-between;
		width: 100%;

		.syncing-icon {
			height: 26px;
		}
	}

	#update-organizations-button {
		margin-top: 0;
	}
}
