@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.field-node-container {
	display: flex;
	flex-wrap: wrap;
	row-gap: size.$size-8;
	border: 1px solid palette.$gray-4;
	position: relative;
	padding: size.$size-8;
	margin: size.$size-16 0;
	margin-bottom: 0;
	border-radius: size.$size-2;

	.ant-tag {
		@include mixins.text-regular(12);
	}

	&:after {
		@include mixins.text-regular(10);
		content: attr(data-after-content);
		top: -12px;
		left: -4px;
		padding: 0rem size.$size-4;
		background-color: palette.$blue-2;
		box-shadow: 0 size.$size-4 size.$size-8 palette.$gray-4;
		color: colors.$default-black;
		text-shadow: 0 1px 1px palette.$gray-7;
		text-align: center;
		position: absolute;
		z-index: 2;
		pointer-events: none;
		border-radius: size.$size-2;
	}
}
