@use 'css/vars/size';

.bulk-edit-modal {
	.ant-modal-body {
		overflow-y: unset !important;
		height: fit-content;
	}

	.bulk-edit-modal-section + .bulk-edit-modal-section {
		padding-top: size.$size-16;
	}
}
