@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';
@use './text' as *;

// ----- LAYOUT OVERRIDES START -----

.ant-layout {
	background-color: colors.$app-background !important;
}

// ----- LAYOUT OVERRIDES END -----

// ----- TABLE OVERRIDES START -----

.ant-table {
	line-height: 20px;

	.ant-table-thead > tr > th {
		padding: size.$size-16;

		+.center-align-cell{
			text-align: center;
		}
	}


	.ant-checkbox-wrapper {
		margin-top: -3px !important;
	}

	.ant-table-tbody {
		> tr {
			> td {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 20vw;
				height: 1px;

				&.ant-table-cell-fix-left-last {
					overflow: unset;
				}
			}

			&.ant-table-expanded-row-level-1 > td {
				padding: 0;
				padding-top: size.$size-8;
				padding-bottom: size.$size-8;
				background: palette.$gray-3;
			}
		}

		.ant-table-placeholder {
			background: transparent !important;
		}
	}

	tfoot > tr > td,
	tfoot > tr > th,
	.ant-table-tbody > tr > td {
		padding: size.$size-8;
	}

	&.ant-table-small > .ant-table-content > .ant-table-body {
		margin: 0;
	}

	.ant-table-column-sorter {
		margin-inline-start: 8px;
	}
}

// ----- TABLE OVERRIDES END -----

// ----- INPUT OVERRIDES START -----

.ant-input-lg {
	line-height: size.$size-24;
}

.ant-modal:not(.flex-inputs) {
	.ant-input:not(.ant-input-lg),
	.ant-input-password:not(.ant-input-password-large) {
		width: size.$input-width;
	}
}

.ant-input-affix-wrapper {
	&.ant-input-password > .ant-input-suffix {
		align-items: center !important;
	}

	&:not(.ant-input-password) {
		.ant-input-suffix:has(> span:only-child) {
			align-items: end !important;
		}
	}
}

// ----- INPUT OVERRIDES END -----

.ant-dropdown-trigger {
	cursor: pointer;
}

.ant-tag {
	@include mixins.text-regular(12);
	display: inline-flex;
	justify-content: center;
	align-items: center;

	span {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		display: block;
	}
}

.ant-tabs-tab,
.ant-radio-button-wrapper {
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.ant-tabs-tab > span > .ant-badge .ant-badge-count {
	margin-left: size.$size-8;
	background-color: palette.$green-6;
}

.ant-tabs-tab + .ant-tabs-tab {
	margin: 0px;
}

.ant-tabs-nav .ant-tabs-tab {
	@include mixins.text-regular(14);
	padding: size.$size-8 size.$size-16;
	margin-right: 0;
}

.ant-empty {
	color: palette.$gray-8;
}

.ant-list-item-action > li {
	color: palette.$gray-8;
}

.ant-card {
	@include mixins.text-regular(14);
	color: colors.$default-black;
}

.ant-form-item {
	margin-bottom: size.$size-16;
}

.ant-form-inline .ant-form-item {
	margin-right: size.$size-16;
}

.ant-list-item-meta-description,
.ant-list-item-meta-title {
	margin-top: size.$size-4;
	line-height: 1em;
}

.ant-form-horizontal .ant-form-item-label {
	min-width: fit-content;
}
