@use 'css/vars/size';

.impersonator-notice {
	width: 400px;
	margin-top: -(size.$size-8);
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-left-radius: size.$size-8;
	border-bottom-right-radius: size.$size-8;
	margin-right: size.$size-16;
}
