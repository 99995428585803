@use 'css/vars/colors';

#timeline-details {
	> .ant-card-body {
		overflow-x: auto;
	}

	.ant-table-header {
		overflow: unset !important;
	}

	.paginated-table table {
		height: 100%;
	}

	.discovered {
		color: colors.$addition;
	}

	.modified {
		color: colors.$modification;
	}

	.vanished {
		color: colors.$deletion;
	}
}
