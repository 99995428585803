@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.notification-card {
	cursor: pointer;
	margin-bottom: size.$size-8;

	@include mixins.animated(background-color);

	&:hover {
		background-color: palette.$gray-2;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	.ant-card-head {
		border: 0;

		.ant-card-head-wrapper {
			.h3 {
				display: inline-block;
				margin-right: size.$size-8;
			}

			> div {
				padding-bottom: 0;
			}

			.ant-card-extra {
				color: palette.$gray-7;

				> span {
					cursor: pointer;
				}
			}
		}
	}

	.ant-card-body {
		padding: 0 size.$size-24 size.$size-16 size.$size-24;
	}

	.notification-message {
		margin-bottom: size.$size-16;
	}

	.notification-timestamp {
		@include mixins.text-regular(12);
		color: palette.$gray-7;
	}

	.mention {
		font-weight: text.$semibold;
		color: palette.$blue-6;
	}

	&.unread {
		background-color: palette.$orange-1;

		.ant-badge-status-dot {
			width: size.$size-8;
			height: size.$size-8;
		}

		&:hover {
			background-color: palette.$orange-2;
		}

		.mention {
			color: palette.$orange-6;
		}
	}
}
