@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.notifications-menu {
	background-clip: padding-box;
	height: calc(100vh - #{size.$size-24});
	top: 32px !important;
	right: 0px !important;
	left: unset !important;
	padding: size.$size-8 0 size.$size-16 0;
	user-select: none;

	&.empty {
		height: auto;

		.ant-card-body {
			@include mixins.elevated();
		}
	}

	.notification-menu-actions {
		margin-right: size.$size-16;
		padding-bottom: size.$size-8;

		button {
			@include mixins.elevated();
		}
	}

	.notification-menu-list {
		background-clip: padding-box;
		height: calc(100% - 64px);
		padding: 0 size.$size-16 size.$size-8 size.$size-24;
		overflow-y: hidden;

		&:hover {
			overflow-y: overlay;
		}
	}

	.notification-card {
		background-clip: padding-box;
		@include mixins.elevated();
		width: 400px;
	}
}

.notifications-menu-icon {
	@include mixins.text-regular(24);
	color: palette.$gray-8;
	cursor: pointer;
	display: flex;
}
