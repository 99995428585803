@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.bulk-add-selection-context-widget {
	display: flex;
	width: 100%;
	align-items: center;
	transition: height 0.2s ease-in, margin-bottom 0.2s ease-out;
	height: 0px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -8px;
	justify-content: space-between;
	max-width: 910px;
	border-width: 0;
	@include mixins.elevated();

	&.open {
		border-width: 1px;
		height: fit-content;
		margin-bottom: 0;
	}

	.bulk-add-exceeded-limit {
		color: palette.$gold-6;
		margin-left: size.$size-8;
	}

	.ant-card-body {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: size.$size-16;
		padding: size.$size-8 size.$size-16;

		div:last-child {
			margin-left: auto;
		}

		.selected {
			white-space: nowrap;
		}

		.clear-selection {
			padding: 0px;
		}
	}
}

.bulk-add-popover {
	@include mixins.elevated();

	max-height: 520px;
	overflow-y: hidden;

	&.disabled {
		display: none;
	}

	&.ant-popover-placement-bottom,
	&.ant-popover-placement-bottomLeft,
	&.ant-popover-placement-bottomRight {
		padding-top: 0;
	}

	.ant-popover-arrow {
		display: none;
	}

	.ant-popover-inner-content {
		padding: 0;
	}

	.bulk-dropdown {
		background: white;
		padding-bottom: size.$size-8;
		min-width: 230px;

		.added-to {
			padding: size.$size-8;
		}

		.ant-dropdown-menu {
			box-shadow: none;
		}

		.confirm-add-tag,
		.confirm-add-task {
			margin-right: size.$size-8;
			flex-direction: row-reverse;
		}

		.add-to-task-row {
			justify-content: space-between;
			margin: 0 size.$size-8;

			.add-to-task-link {
				margin-top: size.$size-4;
			}
		}

		.bulk-dropdown-input {
			padding-top: size.$size-4;
			margin: 0 size.$size-4;
			width: calc(100% - 10px);

			.create-initiative-input,
			.tag-name-input,
			.task-name-input {
				min-width: 222px;
			}
		}

		.bulk-add-button {
			display: block;
			margin: size.$size-8 auto 0px auto;
			width: 170px;
		}

		.name-button-row {
			justify-content: space-between;
			margin: 0 size.$size-16;
		}

		.name-row {
			padding: size.$size-8;
		}

		.results-menu {
			border-bottom: 1px solid palette.$gray-5;
			max-height: 400px;
			overflow-y: scroll;

			.sonar-tag {
				font-size: 14px;
			}
		}
	}
}
