@use 'css/vars/size';

.plaintext-editor {
	height: 100%;
	width: 100%;

	.plaintext-editor-wrapper {
		cursor: text;
		display: flex;
		position: relative;

		textarea {
			cursor: unset !important;
			padding: 0;

			&:disabled {
				color: unset;
			}
		}
	}

	&.single-line {
		.plaintext-editor-wrapper{
			align-items: center;
		}
	}

	&:not(.editing) {
		caret-color: transparent !important;
	}

	&.readonly {
		cursor: not-allowed;
	}

	.action {
		margin: size.$size-4 size.$size-4 0px 0px;
	}
}
