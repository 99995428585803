@use 'css/vars/palette';
@use 'css/vars/size';

.app-status-indicator {
	display: flex;
	align-items: center;

	.status-indicator {
		align-items: center;
		display: inline-flex;
		justify-content: center;
		margin: 0.35em;
		width: auto;

		.status-indicator-inner {
			background-color: palette.$red-7;
			border-radius: 6px;
			height: 12px;
			width: 12px;

			&.operational {
				background-color: palette.$green-7;
			}

			&.unknown {
				background-color: palette.$gray-6;
			}

			&.major,
			&.minor {
				background-color: palette.$orange-7;
			}
		}
	}
}
