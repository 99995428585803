@use 'css/vars/palette';
@use 'css/vars/size';

.jira-issues-summary {
	max-width: size.$initiative-content-max-width;
	
	.summary-label {
		display: flex;
		padding: size.$size-16 size.$size-8 size.$size-8 size.$size-8;

		.jira-icon {
			color: palette.$blue-6;
			font-size: size.$size-24;
		}

		.summary-title {
			margin-left: size.$size-8;
		}
	}

	#jira-issue {
		margin-bottom: size.$size-8;
	}
}
