@use 'css' as *;

.last-sync-status {
    display: inline-flex;
    align-items: center;
    width: 250px;
}

.last-sync-time {
    margin-right: $size-16;
}

.start-sync-button {
    width: 160px;
}

.force-full-refresh-checkbox {
    margin-left: $size-16;
}
