@use 'css/vars/palette';
@use 'css/vars/size';

.initiative-task-progress-bar {
    max-width: size.$initiative-content-max-width;

    .ant-progress-inner {
        background-color: palette.$gray-5;
    }

    .ant-progress-text {
        color: palette.$gray-7;
    }
}

#initiative-task-summary {
    max-width: size.$initiative-content-max-width;

    .anticon-check-circle {
        color: palette.$green-6;
    }
}
