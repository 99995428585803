@use 'css' as *;

.periscope-text-editor {
	border-radius: $size-4;
	border-color: transparent;
	border-style: solid;
	border-width: 1px;

	> span {
		width: 100%;

		> div {
			width: 100%;
		}
	}

	&:not(.active) {
		.DraftEditor-root {
			background: unset;

			.public-DraftEditor-content {
				background-color: unset;
			}
		}

		&:not(.empty) {
			padding: $size-8;
		}

		&:hover {
			border-color: $gray-8;
		}

		&.empty {
			&:not(.spreadsheet) {
				background: $gray-2;
				border-radius: $size-4 !important;

				&:not(:hover) {
					border-color: $gray-5 !important;
					border-style: dotted;
					border-width: 2px;

					.DraftEditor-root {
						padding: 7px;
					}
				}

				.DraftEditor-root {
					.public-DraftEditor-content {
						background-color: $gray-2;
					}
				}
			}

			.public-DraftEditorPlaceholder-root {
				color: $gray-8;
			}
		}
	}

	&.active {
		border-width: 0;

		.DraftEditor-root {
			background: $gray-1;
			border-color: $blue-6 !important;
			border-style: solid;
			border-width: 1px;
			border-radius: $size-4;
			border-bottom-width: 0 !important;
		}

		.public-DraftEditorPlaceholder-inner {
			color: $gray-8;
		}

		&:not(.spreadsheet) > .DraftEditor-root {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		.toolbar {
			border-color: $blue-6;
			border-style: solid;
			border-width: 1px;
			border-radius: $size-4;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-top-width: 0;
			overflow: hidden;

			> div {
				border-bottom-width: 0;
				border-left-width: 0;
				border-right-width: 0;
			}
		}
	}

	&.spreadsheet {
		display: flex;
		word-break: break-word;
		height: 100%;

		.DraftEditor-root {
			background: unset;

			.public-DraftEditor-content {
				background-color: unset;
			}
		}

		&.empty {
			.DraftEditor-root {
				background-color: unset;
				border-color: transparent;

				.public-DraftEditor-content {
					background-color: unset;
				}
			}
		}

		&.active {
			background: $gray-1;
			border-width: 0;
			cursor: text;

			&:hover {
				border-color: transparent;
			}

			.DraftEditor-root {
				height: 100%;
				border-bottom-width: 1px !important;
			}
		}

		.DraftEditor-root {
			width: 100%;
		}
	}
}
