@use 'css/vars/size';

.detail-subsection {
    &:not(:first-child) {
        margin-top: size.$size-16;
    }

    .detail-subsection-header {
        padding-bottom: size.$size-4;

        .detail-subsection-title {
            user-select: none;
        }
    }

    .detail-subsection-body {
        padding: 0 size.$size-4;
    }

    .detail-subsection-body-small-spacing {
        padding: 0 size.$size-2;
    }

    .detail-subsection-body-large-spacing {
        padding: 0 size.$size-8;
    }

    .detail-subsection-body-no-spacing {
        padding: 0;
    }
}
