@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';

.timeline-change-count {
	display: table-cell;
	width: fit-content;

	&.selected .statistic {
		border-color: palette.$blue-5;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 0 0.25rem 0 0;
			border-left: 0.2rem solid transparent;
			border-bottom: 0.2rem solid transparent;
			border-right: 0.2rem solid transparent;
			border-right-color: palette.$blue-5;
			border-top: 0.2rem solid transparent;
			border-top-color: palette.$blue-5;
		}
	}

	&.highlighted .statistic,
	.statistic:hover {
		border-color: palette.$blue-5;
	}

	&.faded .ant-statistic-content {
		opacity: 0.5;
	}

	.statistic {
		border: solid 1px transparent;
		border-radius: size.$size-4;
		padding: size.$size-4;
		line-height: 1em;
		position: relative;
		margin-bottom: size.$size-8;

		&.unmodified .ant-statistic-content {
			color: colors.$unmodified;
		}

		&.discovered .ant-statistic-content {
			color: colors.$addition;
		}

		&.modified .ant-statistic-content {
			color: colors.$modification;
		}

		&.vanished .ant-statistic-content {
			color: colors.$deletion;
		}
	}

	.ant-statistic-content {
		display: flex;
		flex-wrap: nowrap;
		align-items: baseline;
		font-size: size.$size-16;
	}
}
