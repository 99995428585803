@use 'css' as *;

.validation-rule-metadata {
	padding: $size-8;

	.parent-object {
		margin-top: 1.5em;

		.reference-link {
			background: $gray-4;
			display: inline-block;
			cursor: pointer;
			padding: .75em;
			color: $gray-7;
			width: 100%;

			b {
				color: $default-black;
			}
		}
	}
}
