@use 'css/vars/palette';

.disable-data-security-button {
    color: palette.$red-5;

    &:hover {
        color: palette.$red-5;
    }
}

.disable-data-security-popover{
    width: 325px;

    .disable-data-security-confirm-message{
        color: palette.$gray-9;
    }
}
