@use 'css/vars/size';
@use 'css/vars/palette';

.monitor-modal {
    width: 824px !important;
    max-height: 90vh;

    .ant-modal-content {
        padding: 0;
    
        .ant-modal-header {
            padding: 20px size.$size-24 size.$size-16 size.$size-24;
            margin-bottom: 0;
        }   

        .ant-modal-body {
            border-bottom: 1px solid palette.$gray-4;
            border-top: 1px solid palette.$gray-4;
            padding: size.$size-24;
            max-height: calc(90vh - 76px);
            overflow-y: auto;
        }

        .ant-modal-footer {
            padding: 0 size.$size-24 20px size.$size-24;
        }
    }
}
