@use 'css/vars/size';

.selection-info {
	&.ant-alert-info:not(.bordered) {
		background-color: transparent;
		border: none;
		padding: 0px;
		padding-right: size.$size-4;
	}
}
