.sonar-card-tabs {
	.sonar-tabs {
		height: 100%;
		overflow: hidden;
	}

	.ant-card-head {
		border-bottom: 0;
	}

	.ant-tabs-nav {
		margin-bottom: 0;

		.ant-tabs-tab {
			padding: 12px 16px;
		}
	}

	.ant-tabs-content {
		height: 100%;

		> .ant-tabs-tabpane {
			height: 100%;
		}
	}
}
