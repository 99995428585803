@use 'css/vars/size';

#app-table {
	.ant-table-container {
		height: 100%;
	}

	.ant-table-body {
		height: 100%;
	}

	tbody > tr > td {
		vertical-align: top;
		padding: size.$size-16;

		&.wrap-content {
			text-overflow: unset;
			white-space: unset;
		}
	}

	.ant-table-row {
		position: relative;
	}

	.ant-table-cell-fix-left {
		z-index: 300;
	}

	.plaintext-editor-wrapper {
		align-items: flex-start;

		.icons {
			align-items: flex-end;
			top: unset;
			bottom: size.$size-8;
		}
	}
}
