@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.code-item {
	&.tag {
		border-radius: 4px;
		background-color: palette.$gold-3;
		box-shadow:2px 0 0 palette.$gold-3, -2px 0 0 palette.$gold-3;
		padding: 2px 0;
	}

	&.link {
		position: relative;
		cursor: pointer;
	}
}
