@use 'css/vars/palette';
@use 'css/vars/size';

#add-app{
    > .ant-card-body{
        background-color: palette.$gray-3;
		padding: size.$size-8;
	}

    .add-app-buttons{
        display: flex;
        padding: 10px size.$size-16;
        justify-content: flex-end;
        gap: size.$size-8;
    }
}
