@use 'css/vars/palette';
@use 'css/vars/size';

#system-overview-card {
	> .ant-card-body {
		padding: size.$size-16 size.$size-24;
	}

	.system-overview-buttons {
		> .ant-space-item:nth-child(2) {
			height: size.$size-24;
		}
	}

	#view-connected-apps-button {
		border: 0;
		padding: 0;
		height: size.$size-24;

		.ant-space-item {
			height: size.$size-24;
		}
	}

	#app-graph {
		margin-top: size.$size-16;
	}

	.graph-container {
		padding: size.$size-24;
	}

	.empty-graph {
		text-align: center;
	}
}

.system-overview-modal {
	height: 95% !important;
	width: 95% !important;

	> div:nth-child(2) {
		height: 100%;
	}

	.ant-modal-content {
		height: 100%;
		padding: 0;

		> .ant-modal-body {
			height: 100%;

			> .ant-card {
				height: 100%;

				> .ant-card-body {
					height: 100%;

					> .ant-row {
						width: 97%;
					}

					> .loading-spinner {
						height: calc(100% - 90px);

						> div {
							height: 100%;

							> .ant-spin-container {
								height: 80%;
							}
						}
					}
				}
			}
		}
	}
}
