@use 'css/vars/size';

.data-dictionary-bulk-edit-modal {
	> div {
		height: 100%;

		> .ant-modal-content {
			overflow: hidden;
			height: 100%;
		}
	}

	.ant-modal-body {
		overflow-y: auto !important;
		height: calc(100% - 76px);
	}

	.bulk-edit-modal-section + .bulk-edit-modal-section {
		padding-top: size.$size-16;
	}

	.add-to-task-button {
		padding: 0px;
	}
}
