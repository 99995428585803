@use 'css/vars/palette';
@use 'css/vars/size';

.app-monitor-settings {
    > .ant-card-body {
        padding: size.$size-16 size.$size-16 size.$size-8 size.$size-16;
        background-color: palette.$gray-3;
    }

    .app-monitor-list {
        display: flex;
        flex-direction: column;
        gap: size.$size-8;
    }
}
