@use 'css/vars/colors';

#sidebar {
	background: colors.$sidebar-background;
	height: calc(100vh - 48px);
	overflow-x: hidden;
	overflow-y: auto;

	#main-menu {
		.ant-menu-item-selected {
			background-color: colors.$sidebar-accent;
		}
	}
}
