@use 'sass:list';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';

@mixin visually-hidden() {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@mixin elevated() {
	box-shadow:
		0 3px 6px -4px rgba(palette.$gray-13, 0.12),
		0 6px size.$size-16 0 rgba(palette.$gray-13, 0.08),
		0 9px 28px size.$size-8 rgba(palette.$gray-13, 0.05);
}

@mixin animated($target: all, $delay: 0.4s, $timing: cubic-bezier(0.5, 0.5, 0.25, 1)) {
	-webkit-transition: $target $delay $timing;
	-moz-transition: $target $delay $timing;
	-ms-transition: $target $delay $timing;
	-o-transition: $target $delay $timing;
	transition: $target $delay $timing;
}

@mixin text-regular($size, $lineHeight: $size + 8) {
	@if not list.index(text.$textSizes, $size) {
		@error "#{$size} is not a valid size. Expected one of #{text.$textSizes}";
	}

	font-size: $size + px;
	font-weight: text.$regular;
	line-height: $lineHeight + px;
}

@mixin text-semibold($size, $lineHeight: $size + 8) {
	@if not list.index(text.$textSizes, $size) {
		@error "#{$size} is not a valid size. Expected one of #{text.$textSizes}";
	}

	font-size: $size + px;
	font-weight: text.$semibold;
	line-height: $lineHeight + px;
}
