@use 'css/vars/palette';

.app-name-input {
	.ant-select-item.custom-app-option {
		border-top: solid 1px palette.$gray-5;
	}
}

.app-name-input-dropdown {
	top: 90px;
}
