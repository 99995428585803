@use 'css/vars/size';
@use 'css/mixins';

.periscope-paginated-list {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.periscope-list-title {
	@include mixins.text-semibold(12);
	padding-left: size.$size-8;
}
