@use 'css' as *;

.record-parent-prefix {
	color: $gray-6;
	cursor: pointer;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;

	&:hover {
		color: $blue-6;
		border-bottom: 1px dotted $blue-6;
		opacity: 1;
	}

	.anticon {
		padding-right: $size-4;
	}
}
