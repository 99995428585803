#slack-auth {
	.ant-card-body {
		max-width: 800px;
	}

	.copy-button {
		display: block;
	}

	.slack-link-button {
		margin-top: 8px;
		margin-bottom: 8px;
	}
}
