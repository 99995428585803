@use 'css/vars/palette';
@use 'css/vars/size';

.suggestion-button {
	color: palette.$cyan-7;

	button,
	button::selection,
	button:hover,
	button:focus {
		border: 0px;
		background: none;
		box-shadow: none;
	}


	button {
		height: 22px;
		width: 22px;

		.anticon {
			color: palette.$cyan-7;
		}

		&:hover .anticon {
			color: palette.$cyan-6;
		}
	}

}
