@use 'css/vars/palette';
@use 'css/vars/size';

.sonar-selector {
	min-width: 164px;

	.ant-select,
	.ant-select-selector,
	.ant-select-selector:hover,
	.ant-select-selector:focus {
		border: 1px transparent !important;
		background: transparent;
		box-shadow: none !important;
	}

	.ant-select-clear,
	.ant-select-arrow {
		top: min(50%, size.$size-16);
		inset-inline-end: size.$size-4;

		.anticon {
			font-size: 12px;
		}
	}

	.ant-select-selection-search {
		opacity: 0;

		&:focus-within {
			opacity: 1;
		}
	}

	.ant-select {
		max-width: 100%;
	}

	&.horizontal {
		max-width: 100%;

		.ant-select-selection-overflow {
			flex-direction: row;
			column-gap: size.$size-16;

			:nth-child(1) {
				.ant-select-selection-search {
					margin-left: 40px;
				}
			}
		}

		.ant-select-selection-overflow-item {
			padding-left: 0px;
		}
	}

	&.vertical {
		.ant-select-selection-overflow {
			flex-direction: column;
		}

		.ant-select-selection-search {
			margin-left: 40px;
			inset-inline-start: 0;
			inset-inline-end: 0;
		}

		.ant-select-selection-overflow-item,
		.ant-select-selection-item,
		.ant-select-selection-item-content {
			padding-inline-end: 0;
			width: 100%;
		}
	}

	> .anticon {
		font-size: size.$size-32;

		svg {
			height: size.$size-32;
			width: size.$size-32;
		}
	}

	.ant-select-single {
		height: unset;
	}

	.ant-select-selector:not(:hover) {
		border: 1px solid transparent;
		background-color: transparent;
	}

	.ant-select-selector {
		padding: 0px !important;
		min-width: 200px;

		&:after {
			line-height: unset !important;
		}
	}

	.ant-select-selection-placeholder {
		left: 0px;
		padding-inline-end: 0;
	}

	.ant-select-selection-item {
		cursor: text;
		height: fit-content;
		line-height: unset !important;
		background: unset !important;
		border: unset !important;
		padding-inline-start: 0px !important;
		padding-inline-end: 0px;
	}

	&.readonly {
		cursor: default;
		padding: size.$size-4;
		row-gap: size.$size-4;

		&.horizontal {
			column-gap: size.$size-16;
			flex-wrap: wrap;
		}

		&.vertical {
			flex-direction: column;
			width: fit-content;
		}
	}

	.ant-select-multiple .ant-select-item-option-selected {
		.anticon {
			vertical-align: -0.45em;
		}
	}

	.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
		.ant-select-selector {
			background: none;
		}
	}

	&.boxless {
		min-width: 0px;
		border: none;
		padding: 0px;

		.ant-select-selection-item {
			cursor: pointer;
			padding-right: size.$size-24;
		}

		.ant-select-selector {
			min-width: fit-content;
		}

		.activity-indicators {
			margin-right: 0px;
			margin-bottom: 0px;
		}

		&.processing {
			.ant-select-clear,
			.ant-select-arrow {
				opacity: 0 !important;
			}
		}

		.content > .ant-row {
			min-width: fit-content;
		}

		&:hover .ant-select:not(.ant-select-open) {
			.ant-select-arrow {
				color: palette.$gray-10;
			}
		}

		.ant-tag {
			margin-inline-end: 0px;
		}

		.sonar-selector-popup {
			min-width: fit-content;
		}
	};
}
