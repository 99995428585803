@use 'css' as *;

.email-template-metadata {
	padding: 0px $size-4;

	.periscope-code {
		margin-bottom: $size-16;
	}

	b {
		padding-left: $size-4;
	}
}
