
.loading-spinner {
	&.empty-spinner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&.opaque {
		.ant-spin-blur {
			transition: 0.3s;
			opacity: 1;
		}

		.ant-spin-nested-loading {
			.ant-spin-blur {
				transition: 0s;
				opacity: 0;
			}
		}
	}
}
