@use 'css' as *;

#daily-sync-toggle {
	border-top: 1px solid $app-background;
	flex-wrap: wrap;
	padding-top: $size-8;
	text-align: center;

	.enable-daily-sync-toggle {
		margin-top: $size-8;
	}
}
