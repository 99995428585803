@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.admin-dashboard {
	background: colors.$app-background;
	height: 100vh;
	overflow: auto;

	.ant-collapse-header {
		@include mixins.text-regular(24);
	}

	.ant-tag {
		display: inline-block;

		span {
			display: inline;
		}
	}
}

.admin-header {
	display: flex;
	height: 60px;
	padding: 0 size.$size-24;
	background-color: colors.$sidebar-background;
	justify-content: space-between;
	align-items: center;

	.h1 {
		color: palette.$gray-1;
		font-size: 30px;
		margin: 0px;
	}

	.button-panel {
		button {
			margin-left: size.$size-8;
		}
	}
}

.ant-select-item-group {
	@include mixins.text-regular(14);
}

.admin-content {
	margin: size.$size-24;

	#search-shard-catalog {
		max-width: 800px;
		display: flex;

		.ant-select {
			flex: 1;
		}
	}

	#admin-tabs {
		margin-top: size.$size-16;
	}

	.simple-pagination {
		display: block;
		text-align: right;
		margin-top: size.$size-16;
	}
}

.shard-catalog-search {
	margin-bottom: size.$size-24;

	.ant-select .ant-select-selector {
		border-right: none;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
	}

	button {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}
