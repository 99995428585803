@use 'css/vars/palette';
@use 'css/vars/size';

.quick-access {
	width: fit-content;
	height: fit-content !important;

	> .ant-card-head {
		border: none;
	}

	.quick-access-menus {
		display: flex;
		padding: 0px size.$size-24 size.$size-24 size.$size-24;
	}

	.quick-access-menu {
		border-inline-end: none !important;

		.ant-menu-item {
			height: fit-content;
			width: 100%;
			line-height: size.$size-24;
			padding: size.$size-4 0px;
			margin: 0px size.$size-2;
		}

		[group="view"].ant-menu-item {
			max-width: 230px;

			.view-type {
				color: palette.$gray-7;
				font-size: 12px;
			}

			.ant-menu-title-content {
				line-height: 20px;
			}
		}
	}

	.show-all {
		padding-left: size.$size-24;
	}

}
