@use 'css/vars/palette';
@use 'css/vars/size';

.detected-app-card {
	display: flex;
	min-width: 256px;
	height: 100%;
	flex-direction: column;
	gap: size.$size-8;
	border-radius: size.$size-2;
	border: 1px solid palette.$gray-5;

	&.hidden-styling {
		span {
			color: palette.$gray-7 !important;

			&.application-name {
				isolation: isolate;

				> .application-logo {
					mix-blend-mode: luminosity;
				}
			}
		}
	}

	.detected-app-header {
		display: flex;
		flex-direction: column;
		gap: size.$size-8;
		padding: size.$size-4;
	}

	.detected-app-body {
		background-color: palette.$gray-3;
		border-top: 1px solid palette.$gray-5;
		padding: size.$size-8;
		height: 100%;

		.ant-spin-container {
			display: flex;
			flex-direction: column;
			gap: size.$size-8;
		}

		.user {
			width: 100%;
			overflow: hidden;
			text-wrap: nowrap;
			text-overflow: ellipsis;
		}
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}
}

.confirm-hide {
	display: flex !important;

	.confirm-delete {
		width: 100%;
	}
}
