@use 'css/vars/size';
@use 'css/mixins';

.sync-view {
    width: 950px;

    .h1 {
        text-align: center;
    }

    .button-wrapper {
        width: 100%;
        flex-direction: column;
    }

    .sync-status {
        @include mixins.text-regular(16);
        text-align: center;
        font-size: size.$size-16;
    }

    .sub-info {
        @include mixins.text-regular(12);
        text-align: center;
    }
}
