@use 'css' as *;

.issue {
	margin: $size-8 $size-16;

	.ant-row {
		margin-bottom: $size-16;
	}

	.ant-card-head {
		padding: 0;
		@include animated(background-color);

		&:hover {
			background: $gray-4;
		}

		.ant-card-head-title {
			align-items: center;
			display: flex;
			padding: 0;
			flex-wrap: wrap;

			> .ant-space {
				align-items: center;
			}
		}

		.title-button {
			border: none;
			background: none;
			cursor: pointer;
			font-weight: $semibold;
			height: 100%;
			padding: $size-16 $size-24;
			width: 100%;
			-webkit-appearance: none;
			-moz-appearance: none;

			&:focus {
				background: $gray-5;
				outline: 1px solid $gray-1;
			}
		}
	}

	.issue-actions {
		margin-left: auto;
	}

	.ant-card-body {
		padding: 0;

		code {
			display: inline-block;
			padding: $size-8;
			white-space: pre;
			width: 100%;

			.new {
				color: $green-7;
			}

			.old {
				color: $red-7;
			}

			.tag {
				background-color: $red-7;
				box-shadow: 2px 0 0 $red-7, -2px 0 0 $red-7;
				color: $gray-1;
				font-weight: $semibold;
			}

			> div {
				white-space: pre-wrap;
			}
		}
	}
}
