@use 'css/vars/size';

#timeline-summary {
	.summary-sections {
		display: flex;
		padding: size.$size-32;
		column-gap: size.$size-32;

		.charts-and-stats {
			display: flex;
			flex-direction: column;
			row-gap: size.$size-32;
			width: 100%;
			overflow: hidden;

			.top-section {
				display: flex;
				gap: size.$size-32;
				overflow: hidden;
				flex-wrap: wrap;

				.timeline-synopsis,
				.user-changes-chart {
					flex: 1;
				}
			}
		}
	}

	.ant-alert-info {
		margin: size.$size-4;
	}
}
