@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

#search-items {
	.search-type-grouping {
		&:hover .show-more-link {
			opacity: 1;
		}
	}

	.header {
		@include mixins.text-semibold(12);
		color: palette.$blue-5;
		padding: size.$size-8 size.$size-8 size.$size-4 size.$size-8;

		.show-more-link {
			cursor: pointer;
			float: right;
			opacity: 0.5;
		}
	}
}

.search-items-empty {
	color: palette.$gray-1;
}
