@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.selection-table {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.ant-table,
	.ant-table-body,
	.ant-table-container,
	.ant-spin-container,
	.ant-spin-nested-loading {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.ant-table-container {
		display: contents;
	}

	.action-bar {
		padding-bottom: size.$size-24;
	}

	.ant-table:not(.ant-table-scroll-horizontal) {
		.ant-table-header {
			overflow: visible !important;
		}
	}

	.ant-table.ant-table-scroll-horizontal {
		.ant-table-header {
			padding-bottom: 2px;
			margin-bottom: 1px;
		}
	}

	.ant-table-wrapper {
		height: 100%;
		overflow: hidden;
	}

	.ant-table {
		background: transparent;
	}

	.ant-table-body {
		overflow: auto !important;
	}

	.ant-table-sticky-holder {
		background-color: transparent;
	}

	.ant-table-title {
		padding: size.$size-8 0;

		&:empty {
			padding-bottom: 0;
		}
	}

	.simple-pagination {
		float: right;
		padding-top: size.$size-24;
	}

	.ant-table-wrapper:not(.bordered):not(.multi-column) {
		.ant-table-thead > tr > th {
			padding: 0;
			padding-left: size.$size-8;
			background: transparent;
			border-bottom: 0;

			@include mixins.text-semibold(12);
		}

		.ant-table-tbody > tr > td {
			border: none;
			padding: 0px;
		}
	}

	td.ant-table-column-sort,
	.ant-table-tbody > tr.ant-table-row-selected > td {
		background: palette.$gray-1;

		&.ant-table-cell-row-hover {
			background: palette.$gray-2;
		}
	}

	.selection-disabled {
		padding: 0px size.$size-8;
	}

	&:not(.selection-disabled) {
		.ant-table-wrapper:not(.bordered):not(.multi-column) {
			.ant-table-thead > tr > th {
				padding: size.$size-8 0px;
				background: transparent;
			}

			td.ant-table-column-sort,
			.ant-table-tbody > tr.ant-table-row-selected > td,
			.ant-table-cell-scrollbar {
				background: transparent;
			}
		}

		.sonar-record {
			margin-left: 0px;
		}
	}
}
