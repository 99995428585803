@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';

.timeline-synopsis {
	.statistics-section {
		padding: size.$size-8 0px;

		.statistic {
			.ant-statistic-content {
				display: flex;
				flex-wrap: nowrap;
			}

			&.unmodified .ant-statistic-content {
				color: colors.$unmodified;
			}

			&.discovered .ant-statistic-content {
				color: colors.$addition;
			}

			&.modified .ant-statistic-content {
				color: colors.$modification;
			}

			&.vanished .ant-statistic-content {
				color: colors.$deletion;
			}
		}
	}

	.message {
		max-width: 600px;
		color: palette.$gray-8;
	}

	.highlighted {
		color: palette.$gray-10;
		font-weight: bold;
	}
}
