@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.code-highlight {
	@include mixins.text-regular(12);
	color: colors.$default-black;
	border-radius: size.$size-2;
	background: palette.$gray-1;
	border: 1px solid palette.$gray-5;
	white-space: pre-wrap;
	word-break: break-word;
	font-family: text.$code-font;
	tab-size: 8;
	padding: size.$size-16;
	overflow-y: auto;

	&.nested {
		border: 0;
		background: unset;
		padding: 0;
	}
}

