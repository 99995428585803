@use 'css/vars/palette';
@use 'css/vars/size';

.grouped-changes-chart {
	display: flex;
	flex-direction: column;
	gap: size.$size-8;
	width: 100%;
	max-height: 500px;
	min-width: 300px;

	.ant-radio-group {
		display: flex;
		flex-wrap: nowrap;
	}

	.ant-empty {
		height: 270px;
		margin-top: 80px;
		border-bottom: solid size.$size-4 palette.$gray-4;

		.ant-empty-image {
			height: 118px;

			> svg {
				height: 118px;
				width: auto;
			}
		}
	}
}
