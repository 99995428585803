@use 'css' as *;

.menu-sonar-logo {
	align-items: center;
	color: $gray-1;
	cursor: pointer;
	display: flex;
	justify-content: center;
	margin: $size-16;
	text-align: center;
}
