@use 'css/vars/size';

.access-statistics-legend {
	display: flex;
	flex-wrap: wrap;
	column-gap: size.$size-8;

	.legend-item {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		text-wrap: nowrap;
	}

	.color-bar {
		height: 10px;
		width: 20px;
	}
}
