@use 'css/vars/size';

.app-monitor-cell {
    width: 169px !important;
    cursor: pointer;
    display: grid;
    
    .edit-icons {
        position: absolute;
        right: size.$size-2;
        top: size.$size-2;
    
        .edit-icon {
            font-size: size.$size-16;
            visibility: hidden;
        }
    }

    .add-icons {
        justify-items: center;
        display: grid;
    }
}
