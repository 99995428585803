@use 'css/vars/palette';
@use 'css/vars/size';

.app-overview-legend {
	margin-top: 6px;
	font-size: 14px;

	.controls-header,
	.groups-header {
		color: palette.$gray-7;
		font-weight: 600;
	}

	.hide-detected-apps-checkbox {
		color: palette.$gray-8;
	}

	.grouping {
		display: flex;
		align-items: start;
	}

	.group-dot {
		border-style: solid;
		border-radius: 6px;
		border-width: 1px;
		height: 12px;
		width: 20px;
		min-width: 20px;
		display: inline-block;
		margin-top: size.$size-2;
	}

	.group-label {
		margin-left: size.$size-4;
		color: palette.$gray-8;
		font-weight: 400;
		line-height: size.$size-16;
	}
}
