@use 'css/mixins';

.app-details-popover {
    .detail-subsection-title {
        @include mixins.text-semibold(14);
    }

    .app-description {
        max-width: 324px;
    }
}
