@use 'css' as *;

.issue-options {
	width: 180px;

	.ant-popover-inner-content {
		padding: 0;
	}

	&.ant-popover-placement-bottom,
	&.ant-popover-placement-bottomLeft,
	&.ant-popover-placement-bottomRight {
		padding-top: 0;
	}

	.ant-popover-arrow {
		display: none;
	}

	.option {
		cursor: pointer;
		display: flex;
		height: 48px;
		width: 100%;

		.option-text {
			margin: $size-16;
		}
	}

	.ant-menu .ant-menu-item-selected {
		background-color: $gray-1;
	}
}
