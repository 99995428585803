@use 'css' as *;

button.copy-text {
	&.highlight {
		display: flex;
		max-width: 100%;
		align-items: center;

		&.copied {
			user-select: text;
		}

		> span::selection {
			color: unset;
			background-color: $blue-1;
		}

		&.ant-btn-default:hover, &.ant-btn-default:focus {
			color: unset;
			border-color: $gray-5;
		}

		.ant-btn-icon {
			padding-top: $size-2;
			flex-shrink: 0;
		}

		span {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.failed {
		border-color: $red-5;
	}
}
