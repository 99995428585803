@use 'css/vars/palette';
@use 'css/vars/size';

.data-dictionary-table {
	.ant-table-container {
		overflow: hidden;
	}

	&.no-click {
		.ant-table-row {
			cursor: default;
		}
	}

	table {
		height: 1px;
	}

	td {
		background-color: palette.$gray-1;
	}

	tbody {
		vertical-align: top;
		background-color: palette.$gray-1;
		position: relative;

		.ant-table-row-level-0:first-child::after {
			border: 10px solid black;
		}

		tr:hover .source-link {
			visibility: initial;
		}
	}

	.source-link {
		visibility: hidden;
	}

	.ant-table-cell {
		&.boolean,
		&.checkmark,
		&.tags {
			min-width: 100px;
		}

		&.code,
		&.datetime,
		&.date,
		&.integratedApps,
		&.time,
		&.owner,
		&.enum,
		&.primary,
		&.text {
			min-width: 165px;
		}

		&.definition,
		&.description,
		&.helptext,
		&.notes,
		&.owner,
		&.stakeholder {
			min-width: 265px;
		}

		.label {
			max-width: calc(100% - #{size.$size-16});
		}

		.source-link {
			width: size.$size-24;
			margin-top: - size.$size-2;
		}

		&.ant-table-cell-fix-left {
			z-index: 102;
		}

		&.owner,
		&.stakeholder {
			overflow: visible;
		}

		&:not(th).owner,
		&:not(th).stakeholder {
			padding: 0px;
		}
	}

	.ant-table-row {
		cursor: pointer;
	}

	.ant-table-tbody > tr > td {
		position: relative;
		white-space: break-spaces;

		&.checkmark {
			text-align: center;

			> span {
				font-size: 20px;
			}
		}
	}

	.periscope-source {
		margin-left: size.$size-4;
	}

	.selection-info {
		.ant-alert-content {
			flex: none;
		}
	}

	.stakeholder-selector .ant-select {
		width: 100%;
	}

	.data-dictionary-column-header {
		.salesforce-logo {
			min-width: 20px;
			max-width: 20px;
		}
	}
}
