@use 'css/mixins';

.periscope-field-group {
	margin-top: 0.5em;
	margin-bottom: 1.25em;

	> span {
		@include mixins.text-semibold(16);
	}
}
