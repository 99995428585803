@use 'css/vars/size';

.selected-objects {
	display: flex;
	flex-direction: column;
	flex: 1 0 0;

	.edit-buttons {
		margin-top: size.$size-8;
		margin-bottom: 10px;

		> .ant-space-item:last-child {
			display: contents;
		}
	}
}
