@use 'css' as *;

.filter-buttons {
	padding: $size-8 $size-8 $size-8 $size-4;
	overflow: hidden;
	background-color: inherit;
	border-top: 1px solid  $gray-4;
}

.multi-select-dropdown {
	min-width: 200px;
	max-width: 50vw;
}
