@use 'css/vars/palette';
@use 'css/vars/size';

.add-app-section {
    gap: size.$size-8;
    display: flex;
    padding: size.$size-8;
    flex-direction: column;
    flex: 1 0 0;
    max-width: 50%;

    > form {
        background: palette.$gray-1; 
    }
}
