@use 'css/vars/size';

#setup-audit-trail-view {
	.setup-audit-trail-view-content {
		display: flex;
		flex-direction: column;
		row-gap: size.$size-8;
		overflow: hidden;

		> .ant-row {
			row-gap: size.$size-8;
		}
	}

	.sonar-card .ant-card-head {
		border-bottom: none;
	}

	.setup-audit-trail-view-header {
		.ant-card-head {
			padding: size.$size-16 size.$size-24;
		}

		.ant-card-body {
			display: none;
		}
	}
}
