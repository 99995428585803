@use 'css/vars/palette';
@use 'css/vars/size';

.monitor-card {
    .monitor-card-body {
        width: 100%;

        > .ant-space-item:last-child {
            width: 100%;
        }
    }

    .edit-icon {
        font-size: size.$size-16;
        visibility: hidden;
    }

    &.can-edit {
        cursor: pointer;

        &:hover {
            border-color: palette.$gray-10;

            .edit-icon {
                visibility: visible;
            }
        }
    }
}
