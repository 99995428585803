@use 'css/vars/size';

.recommended-action-templates-section {
	padding: size.$size-16 size.$size-24;
	padding-bottom: size.$size-8;
	
	.title {
		padding-bottom: size.$size-8;
	}

	.subheading {
		display: block;
		padding-bottom: size.$size-16
	}

	.recommended-action-templates {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: size.$size-8;
		padding: size.$size-8 0;
	}
}
