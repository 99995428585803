@use 'css/vars/size';

.vertical-column {
	> .ant-space {
		width: 100%;
	}

	.column-row {
		display: flex;
		flex-wrap: wrap;
		gap: size.$size-8;

		> :first-child {
			flex-basis: fit-content;
 			flex-shrink: 0;

			table {
				width: fit-content;
			}
		}

		> :nth-child(2) {
			flex-basis: 0;
			flex-grow: 1;
 			min-width: 1px;
		}
	}
}

@media (max-width: 1280px) {
	.vertical-column {
		flex: 0 0 100%;
		max-width: 100%;
		min-width: 600px;
	}
}

@media (max-width: 1990px) {
	.column-row > * {
		flex-basis: 100% !important;
		width: 100%;

		table {
			width: 100% !important;
		}
	}
}
