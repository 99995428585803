@use 'css/vars/size';

.settings-section {
	padding-bottom: size.$size-16;

	.title {
		padding-bottom: size.$size-8;
	}

	.subheading {
		display: block;
		padding-bottom: size.$size-16
	}
}
