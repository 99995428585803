@use 'css' as *;

.jira-credentials-edit {
	.ant-popover-inner-content {
		padding: 0;
	}

	.ant-popover-arrow {
		display: none;
	}
}
