@use 'css/vars/size';

.jira-selected-projects {
	.h3 {
		padding-bottom: size.$size-8;
	}

	.jira-project {
		padding-left: size.$size-4;
		padding-bottom: size.$size-8;
	}

	.jira-issue,
	.status-mappings {
		padding-left: size.$size-8;
		padding-bottom: size.$size-8;
	}
}
