@use 'css' as *;

.image-file-crop {
    .image-file-crop-container {
        height: 45vh;
        position: relative;
    }

    .image-file-crop-controls {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;

        > div {
            position: relative;
            padding: 0 $size-24;

            .anticon {
                position: absolute;
                top: -2px;
                width: $size-16;
                height: $size-16;
                color: $gray-6;
                font-size: $size-16;
                line-height: 1;

                &:first-child {
                    left: 0;
                }

                &:last-child {
                    right: 0;
                }
            }
        }
    }
}
