@use 'css/vars/size';
@use 'css/mixins';

.snooze-issue-menu {
	&.snooze-options {
		.ant-menu {
			width: 390px;
		}
	}

	.ant-popover-inner {
		padding: 0;
	}

	.ant-menu {
		.snooze-issue-calendar {
			height: auto;
			padding: 0;

			.ant-menu-title-content {
				flex-direction: column-reverse;
				vertical-align: top;

				> div:not(.ant-picker) {
					display: contents;
				}
			}
		}

		.snooze-back-button {
			padding-left: 0;
		}

		.ant-menu-title-content {
			display: inline-flex;
			width: 100%;

			span:first-child {
				@include mixins.text-semibold(14);
			}

			span:last-child {
				@include mixins.text-regular(14);

				flex-grow: 1;
				text-align: right;
			}

			> .ant-btn {
				> .anticon {
					margin-right: 0;
				}
			}
		}

		.ant-picker {
			visibility: hidden;
			height: 0;
			padding: 0;
			width: 0;
		}

		.ant-picker-footer {
			display: none;
		}

		.ant-picker-dropdown {
			position: relative;
		}
	}
}
