@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';

.search-item {
	border-left: size.$size-4 solid transparent;
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr min-content;
	padding: 0 size.$size-8;

	&:hover {
		border-color: palette.$blue-6;

		.name {
			color: palette.$blue-6;

			.secondary-text {
				color: inherit;
			}
		}
	}

	.name {
		color: palette.$gray-1;
		text-wrap: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		> .ant-space {
			width: 100%;

			.ant-space-item:last-child {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.secondary-text {
			color: palette.$gray-6;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}

	.active-indicator {
		align-self: center;
		display: flex;
	}

	.version {
		font-weight: text.$regular;
		padding-left: .4em;
	}
}
