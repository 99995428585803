@use 'css/vars/size';

.app-count-tag-popover {
    .ant-popover-title {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .ant-popover-inner-content {
        padding-top: size.$size-8;
    }
}
