@use 'css/vars/palette';
@use 'css/vars/size';

.periscope-search-filters {
	background-color: unset;
	border: none;
	margin: size.$size-4;

	> div {
		padding: size.$size-8 !important;
		.ant-col {
			padding: 0!important;
		}
	}
}

.shadow {
	-webkit-box-shadow: 0px 0px 23px -10px palette.$gray-7;
	-moz-box-shadow: 0px 0px 23px -10px palette.$gray-7;
	box-shadow: 0px 0px 23px -10px palette.$gray-7;
}
