@use 'css/vars/size';
@use 'css/mixins';

.initiative-title-bar {
	.title {
		margin-top: 0px;
		left: 0px;
		flex-grow: 1;
		overflow: auto;

		.ant-typography-edit-content-confirm {
			@include mixins.text-regular(16);
		}

		textarea {
			@include mixins.text-regular(16);
			flex-grow: 1;
			min-height: size.$size-32;
			width: 100%;
		}
	}
}
