@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';

.field-scope-header {
	background: palette.$gray-1;
	color: colors.$default-black;
	padding: size.$size-8 size.$size-16;
	display: flex;
	justify-content: space-between;
	gap: size.$size-8;
	align-items: center;

	.ant-space-item {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.popover-selector > div {
			display: flex;
			align-items: center;
		}
	}

	.ant-space {
		display: flex;
		flex-wrap: wrap;
	}

	.record-references {
		.ant-space-item {
			gap: size.$size-4;
		}

		.count-tag {
			margin-left: 0;
		}
	}
}
