@use 'css/vars/palette';
@use 'css/vars/size';

#api_volume_table {
	.back-to-overview-button {
		color: palette.$gray-7;
		display: flex;
		padding: 0;
		width: fit-content;
		height: fit-content;
	}

    .tooltip {
        color: palette.$gray-7;
    }

	.ant-card-head {
		padding: 0px size.$size-16;
	}

	.ant-card-body {
		padding: 0;
	}
}
