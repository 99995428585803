@use 'css/vars/palette';

.view-label {
	box-sizing: border-box;
	flex-wrap: nowrap;
	padding: 5px 12px;
	width: 100%;

	> :last-child:not(:only-child) {
		margin-left: auto;
	}

	.view-label-action {
		visibility: hidden;
	}

	&:hover {
		.view-label-action {
			visibility: initial;
		}
	}
}

.ant-select-selection-item {
	> .view-label {
		padding: initial;
		gap: 0 !important;

		.view-label-action {
			display: none;
		}
	}
}
