@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';

.gateway {
	background: radial-gradient(circle, palette.$blue-8 0%, rgba(colors.$gateway-background, 0) 100%), url('../../../images/gateway-background.svg'), colors.$gateway-background;
	background-position: center center;
	display: grid;
	flex-direction: row;
	grid-template-rows: 1fr auto;
	height: 100vh;

	.ant-alert,
	.sub-text {
		margin-bottom: size.$size-24;
	}

	.sub-text {
		display: block;
	}

	.sonar-logo {
		align-items: flex-end;
		background-image: url('../../../images/gateway-sonar-logo.svg');
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		margin-bottom: size.$size-24;
		height: 75px;
		width: 192px;
	}

	.content {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	[class^="ant-spin"],
	[class*=" ant-spin"] {
		font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
		font-size: 14px;
		box-sizing: border-box;
	}
	
	[class^="ant-spin"]::before,
	[class*=" ant-spin"]::before,
	[class^="ant-spin"]::after,
	[class*=" ant-spin"]::after {
		box-sizing: border-box;
	}

	[class^="ant-spin"] [class^="ant-spin"],
	[class*=" ant-spin"] [class^="ant-spin"],
	[class^="ant-spin"] [class*=" ant-spin"],
	[class*=" ant-spin"] [class*=" ant-spin"] {
		box-sizing: border-box;
	}

	[class^="ant-spin"] [class^="ant-spin"]::before,
	[class*=" ant-spin"] [class^="ant-spin"]::before,
	[class^="ant-spin"] [class*=" ant-spin"]::before,
	[class*=" ant-spin"] [class*=" ant-spin"]::before,
	[class^="ant-spin"] [class^="ant-spin"]::after,
	[class*=" ant-spin"] [class^="ant-spin"]::after,
	[class^="ant-spin"] [class*=" ant-spin"]::after,
	[class*=" ant-spin"] [class*=" ant-spin"]::after {
		box-sizing: border-box;
	}

	.ant-spin {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: #1677ff;
		font-size: 0;
		line-height: 1.5714285714285714;
		list-style: none;
		font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
		position: absolute;
		display: none;
		text-align: center;
		vertical-align: middle;
		opacity: 0;
		transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	}

	.ant-spin-spinning {
		position: static;
		display: inline-block;
		opacity: 1;
	}

	.ant-spin-nested-loading {
		position: relative;
	}

	.ant-spin-nested-loading > div > .ant-spin {
		position: absolute;
		top: 0;
		inset-inline-start: 0;
		z-index: 4;
		display: block;
		width: 100%;
		height: 100%;
		max-height: 400px;
	}

	.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
		position: absolute;
		top: 50%;
		inset-inline-start: 50%;
		margin: -10px;
	}

	.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
		position: absolute;
		top: 50%;
		width: 100%;
		padding-top: 5px;
		text-shadow: 0 1px 2px #ffffff;
		font-size: 14px;
	}

	.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
		margin-top: -20px;
	}

	.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
		margin: -7px;
	}

	.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
		padding-top: 2px;
	}

	.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
		margin-top: -17px;
	}
	
	.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
		margin: -16px;
	}

	.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
		padding-top: 11px;
	}

	.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
		margin-top: -26px;
	}

	.ant-spin-nested-loading .ant-spin-container {
		position: relative;
		transition: opacity 0.3s;
	}

	.ant-spin-nested-loading .ant-spin-container::after {
		position: absolute;
		top: 0;
		inset-inline-end: 0;
		bottom: 0;
		inset-inline-start: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background: #ffffff;
		opacity: 0;
		transition: all 0.3s;
		content: "";
		pointer-events: none;
	}

	.ant-spin-nested-loading .ant-spin-blur {
		clear: both;
		opacity: 0.5;
		user-select: none;
		pointer-events: none;
	}

	.ant-spin-nested-loading .ant-spin-blur::after {
		opacity: 0.4;
		pointer-events: auto;
	}

	.ant-spin-tip {
		color: rgba(0, 0, 0, 0.45);
	}

	.ant-spin .ant-spin-dot {
		position: relative;
		display: inline-block;
		font-size: 20px;
		width: 1em;
		height: 1em;
	}

	.ant-spin .ant-spin-dot-item {
		position: absolute;
		display: block;
		width: 9px;
		height: 9px;
		background-color: #1677ff;
		border-radius: 100%;
		transform: scale(0.75);
		transform-origin: 50% 50%;
		opacity: 0.3;
		animation-name: css-antSpinMove;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-direction: alternate;
	}

	.ant-spin .ant-spin-dot-item:nth-child(1) {
		top: 0;
		inset-inline-start: 0;
	}

	.ant-spin .ant-spin-dot-item:nth-child(2) {
		top: 0;
		inset-inline-end: 0;
		animation-delay: 0.4s;
	}

	.ant-spin .ant-spin-dot-item:nth-child(3) {
		inset-inline-end: 0;
		bottom: 0;
		animation-delay: 0.8s;
	}

	.ant-spin .ant-spin-dot-item:nth-child(4) {
		bottom: 0;
		inset-inline-start: 0;
		animation-delay: 1.2s;
	}

	.ant-spin .ant-spin-dot-spin {
		transform: rotate(45deg);
		animation-name: css-antRotate;
		animation-duration: 1.2s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	.ant-spin-sm .ant-spin-dot {
		font-size: 14px;
	}

	.ant-spin-sm .ant-spin-dot i {
		width: 6px;
		height: 6px;
	}

	.ant-spin-lg .ant-spin-dot {
		font-size: 32px;
	}

	.ant-spin-lg .ant-spin-dot i {
		width: 14px;
		height: 14px;
	}

	.ant-spin.ant-spin-show-text .ant-spin-text {
		display: block;
	}

	@keyframes css-antSpinMove {
		to { opacity:1; }
	}

	@keyframes css-antRotate {
		to{ transform:rotate(405deg); }
	}
}
