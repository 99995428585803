@use 'css/vars/size';
@use 'css/mixins';

.initiative-sidebar {
	.user-selector {
		width: 100%;
		padding: 8px 0px 8px 8px;
	}

	.ant-select {
		width: 100%;
	}

	.created-by,
	.read-only {
		margin-left: size.$size-8;
	}

	.section-header {
		@include mixins.text-semibold(16);
		display: block;
		padding-top: size.$size-24;
		padding-bottom: size.$size-8;
	}
}
