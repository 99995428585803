@use 'css' as *;

.user-tooltip {
	max-width: none;
	
	.tooltip-content {
		display: flex;
		column-gap: $size-8;
		align-items: center;
		color: $gray-1;

		.tooltip-details {
			display: flex;
			flex-direction: column;
			max-width: 300px;
		}
	}
}
