@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.email-alert-details {
	.sonar-record {
		margin: 0px;
	}

	.cc-emails {
		@include mixins.text-regular(12);
		color: palette.$gray-13;
		border-radius: size.$size-4;
		background: palette.$gray-1;
		border: 1px solid palette.$gray-5;
		white-space: pre-wrap;
		word-break: break-word;
		font-family: text.$code-font;
		tab-size: 8;
		line-height: 20px;
		padding: size.$size-16;
		overflow-y: auto;
	}
}
