@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

#uptime-montitors-dashboard {
	.ant-card-body {
		padding-top: 0px;
		overflow: visible;
	}

	.g2-tooltip {
		padding: 0 !important;
		height: 50px;
	}

	.tooltip-container {
		position: absolute;
		top: 10px;
	}

	.ant-tooltip,
	.ant-tooltip-open {
		visibility: inherit;
	}

	.monitor-periods {
		min-width: 0px;
		display: flex;
		column-gap: size.$size-24;

		.ant-statistic-title,
		.ant-statistic-content {
			display: flex;
			flex-wrap: nowrap;
			text-wrap: nowrap;
			align-items: baseline;
		}

		.ant-statistic-content-value {
			@include mixins.text-semibold(30)
		}

		.statistic-delta {
			@include mixins.text-semibold(20)
		}

		.monitor-period {
			width: 100%;
			min-width: 50px;
		}

		.monitor-title {
			color: palette.$gray-7;
			@include mixins.text-semibold(14)
		}
	}
}
