@use 'css/vars/palette';
@use 'css/vars/size';

.user-selector {
	.sonar-user {
		width: 100%;

		:nth-child(2) {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.ant-select-item-option {
		&.disabled {
			.user-label {
				color: palette.$gray-6;
			}
		}
	}

	&.spreadsheet {
		.ant-select {
			width: 100%;
		}
	}
}

.user-selector-popup {
	.ant-select-item-option {
		&.disabled {
			.user-label {
				color: palette.$gray-6;
			}
		}
	}
}

.space-conscious-owner-label {
	position: fixed;

	.ant-select-item {
		padding: size.$size-4;

		&.disabled {
			.user-label {
				color: palette.$gray-6;
			}
		}
	}

	.ant-select-item-option-content {
		.sonar-user {
			width: 100%;

			.ant-space-item:nth-child(2) {
				width: 100%;
				overflow: hidden;

				.user-label {
					display: block;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
