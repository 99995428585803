@use 'css/vars/size';
@use 'css/mixins';

.generic-list {
	.list-title {
		@include mixins.text-semibold(12);
		padding-left: size.$size-8;
	}

	.ant-empty {
		padding-top: size.$size-8;
		padding-bottom: size.$size-8;
		margin-left: size.$size-4;
		margin-right: size.$size-4;
	}
}
