@use 'css/vars/size';

.compact-scope-action-bar {
	padding-right: size.$size-8;
	vertical-align: middle;
	justify-content: end;
	width: 100%;

	> :last-child {
		width: 100%;
		max-width: 384px;
	}
}
