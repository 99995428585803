@use 'css/vars/palette';
@use 'css/vars/size';

.data-dictionary-filter-tag {
	margin-right: 0;
	max-height: 22px;
	max-width: 650px;

	> span {
		text-overflow: unset;
		overflow: visible;
	}

	.filter-label {
		color: palette.$gray-7;
		padding-right: size.$size-4;
	}

	.filter-value {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
