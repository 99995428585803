@use 'css' as *;

.admin-users-panel {
    .user-email-with-issuer {
        display: flex;
        align-items: center;
        line-height: $size-32;
        height: $size-32;

        img {
            margin-right: $size-8;
        }
    }

    .user-issuer-icon {
        display: flex;
        align-items: center;

        img {
            width: $size-32;
            height: $size-32;
        }
    }

    .user-email-filter {
        width: 600px;
        display: block;
        margin-bottom: $size-16;
    }

    .editable-table-cell {
        width: 100%;
    }

    .ant-typography {
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
    }
}
