@use 'css/vars/size';

.application-display {
	display: flex;
	flex-direction: column;
	user-select: none;
	max-width: 100%;
	width: fit-content;

	.application-name {
		height: 40px;
		font-size: size.$size-24;
		max-width: 100%;
		overflow: hidden;
		justify-content: flex-start;
		width: fit-content;

		> span {
			padding-right: size.$size-8;
		}

		.application-name-text {
			display: block;
			overflow: hidden;
			line-height: 28px;
			text-overflow: ellipsis;
		}
	}

	.application-logo {
		height: 24px;
	}

	.application-label {
		margin-top: -6px;
		width: fit-content;
	}

	.ant-spin-container {
		display: flex;
		flex-direction: column;
	}
}
