@use 'css' as *;

.sonar-record {
	.active-indicator {
		margin-left: 0.5em;
	}

	.version {
		font-weight: $regular;
		padding-left: $size-8;
	}
}
