@use 'css' as *;

.initiative-comment {
	background-color: unset;

	.mention {
		font-weight: $semibold;
		color: $blue-6;
	}
}
