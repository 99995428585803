.dynamic-scope-table-filters {
	.arrangers {
		flex-wrap: nowrap;
	}

	.filter-container {
		max-width: 256px !important;
		width: 256px !important;

		.filter {
			width: 256px !important;
		}
	}
}
