@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.links-footer {
	@include mixins.text-regular(14);
	align-items: center;
	background-color: transparent;
	display: flex;
	justify-content: center;
	padding: size.$size-24;
	width: 100%;

	ul {
		margin: 0px;
		padding: 0px;
		text-align: center;

		li {
			display: inline;
			list-style-type: none;

			a {
				color: palette.$gray-1;
				padding: 0px size.$size-8;
			}

			&:after {
				color: palette.$gray-1;
				content: '-';
			}

			&:last-child {
				&:after {
					content: '';
				}
			}
		}
	}
}
