@use 'css' as *;

.issues-list {
	.issue {
		&:first-of-type {
			margin-top: $size-16;
		}
		&:last-of-type {
			margin-bottom: $size-16;
		}
	}
}
