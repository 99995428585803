@use 'css/vars/palette';
@use 'css/vars/size';

.app-status-message {
	padding: size.$size-16;

	.status-message-title {
		display: flex;
		flex-direction: row;
		column-gap: size.$size-8;
		align-items: center;
	}

	.status-message-attribution > a {
		padding-left: size.$size-4;
	}
}
