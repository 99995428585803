@use 'css/vars/size';

.shared-data-dictionary-view {
    padding: size.$size-8;
    padding-bottom: 0;
}

.invalid-view {
    height: 100vh;
    width: 100%;
    justify-content: center;

    .invalid-view-empty {
        > .ant-empty-image {
            height: 118px;
            margin-bottom: 32px;

            > svg {
                height: 118px;
                width: auto;
            }
        }
    }
}

.shared-view-header {
    justify-content: space-between;
}

.shared-view-footer-logo {
    position: absolute;
    bottom: -(size.$size-4);
}
