@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.apex-code {
	@include mixins.text-regular(12);
	color: palette.$gray-13;
	border-radius: size.$size-4;
	background: palette.$gray-1;
	border: 1px solid palette.$gray-5;
	white-space: pre-wrap;
	word-break: break-word;
	font-family: text.$code-font;
	tab-size: 8;
	line-height: 20px;
	padding: size.$size-16;
	overflow-y: auto;

	.apex-code-inner {
		position: relative;
		background: transparent;
		z-index: 0;
	}

	/* Keyword */
	.highlight-1 {
		color: palette.$blue-7;
	}

	/* Type */
	.highlight-2 {
		color: palette.$magenta-7;
	}

	/* NumberLiteral */
	.highlight-3 {
		color: palette.$orange-6;
	}

	/* StringLiteral */
	.highlight-4 {
		color: palette.$red-7;
	}

	/* SoqlLiteral */
	.highlight-5 {
		color: palette.$purple-6;
	}

	/* Comment */
	.highlight-6 {
		color: palette.$green-7;
	}

	/* SfdcEntity */
	.highlight-7 {
		color: palette.$gray-13;
	}

	/* SfdcField */
	.highlight-8 {
		color: palette.$gray-13;
	}

	/* SfdcApexClass */
	.highlight-9 {
		color: palette.$gray-13;
	}

	/* Annotation */
	.highlight-10 {
		color: palette.$cyan-8;
	}
}
