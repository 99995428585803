@use 'css/vars/palette';

#organizations-table {
	height: 100%;
	overflow-x: auto;

	.org-type-tag {
		display: inline-block;
	}

	.title {
		&.ant-typography-edit-content {
			margin-top: 5px;
			margin-left: 10px;
			width: 100%;
		}
	}

	.ant-table-cell {
		white-space: nowrap;

		&:before {
			background-color: transparent !important;
		}

		.ant-btn {
			text-transform: capitalize;
		}

		.organization-title {
			display: flex;
			justify-content: space-between;

			.title-edit {
				margin-top: 3px;
				margin-left: 20px;
			}
		}
	}

	.syncing-icon {
		color: palette.$gold-6
	}

	.failed-icon {
		color: palette.$red-6
	}
}
