@use 'css' as *;

.jira-popover {
	.popover-close {
		position: absolute;
		right: 0;
        top: 0;
		background: none;
		border: none;
        outline: none;

		.anticon-close {
			padding: 20px;
		}
	}

	.jira-popover-header,
	.jira-popover-footer,
	.jira-popover-body {
		display: flex;
		padding: $size-16;
	}

	.jira-popover-header {
		border-bottom: 1px solid $gray-4;
	}

	.jira-popover-footer {
		justify-content: space-between;
		border-top: 1px solid $gray-4;

		:nth-child(2) {
			> * + * {
				margin-left: $size-8;
			}
		}
	}
}
