@use 'css' as *;

#sign-up {
	max-width: 385px;

	.terms-row {
		margin: -8px $size-8 $size-16 $size-8;

		> label {
			align-items: center;

			.ant-checkbox {
				margin-right: $size-8;
			}
		}
	}
}
