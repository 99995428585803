@use 'css' as *;

#initiative-view {
	.not-dragging {
		transform: translate(0px, 0px) !important;
	}

	td {
		padding: $size-8;

		> .scope-item {
			margin-bottom: 0;
		}
	}

	.scope-item {
		&.vanished {
			cursor: default;
			background-color: $gray-3;
		}
	}
}
