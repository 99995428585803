.sonar-card {
    display:flex;
    flex-direction: column;

    > .ant-card-head {
        min-height: 66px;
        display: flex;
        flex-direction: column;
    }

    > .ant-card-body {
        flex: 1;
        overflow: hidden;
    }

    &.no-spacing {
        > .ant-card-body {
            padding: 0;
        }
    }
}
