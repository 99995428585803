@use 'css/vars/palette';
@use 'css/vars/size';

.add-app-settings-section {
    .add-app-settings-form {
        padding: size.$size-16 size.$size-16 0px size.$size-16;

        .app-display{
            display: flex;
            flex-direction: column;

            .application-name {
                height: 32px;
                margin: 0px;
                font-size: 14px;
                overflow: hidden;
                justify-content: flex-start;
                width: 100%;
            }
        }
    }
}
