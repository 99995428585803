@use 'css/vars/palette';
@use 'css/vars/size';

.view-label-share {
    &.view-label-share-active {
        color: palette.$blue-6;
    }
}

.view-share-link-modal {
    max-width: 456px;

    .share-link-label {
        display: block;
        margin-bottom: size.$size-8;
        width: 100%;
    }

    .share-link-actions {
        width: 100%;

        > :first-child {
            max-width: 400px;
        }

        .delete-share-link {
            font-size: 18px;
        }
    }
}
