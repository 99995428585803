@use 'css' as *;

#jira-issue {
	white-space: nowrap;

	.jira-label {
		display: inline-flex;
		overflow: hidden;
		width: 100%;

		.issue-key {
			color: $blue-6;
			font-weight: $semibold;
			margin-left: $size-24;
			margin-right: $size-8;
		}
	}

	.issue-more {
		margin: auto;
	}

	.issue-options {
		&.ant-popover-placement-bottom,
		&.ant-popover-placement-bottomLeft,
		&.ant-popover-placement-bottomRight {
			padding-top: 0;
		}

		.ant-popover-arrow {
			display: none;
		}
	}

	.sync-icon {
		color: $blue-6;
		margin-left: $size-8;
		margin-top: $size-4;

		&.missing {
			color: $red-7;
		}

		&.incomplete {
			color: $gold-6;
		}

		&.loading {
			svg {
				animation-name: spin;
				animation-duration: 4000ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				@keyframes spin {
					from {
						transform: rotate(0deg);
					}
					to {
						transform: rotate(360deg);
					}
				}
			}
		}
	}
}
