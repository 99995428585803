@use 'css/vars/size';

.app-monitor-notifications {
    gap: size.$size-4;
    display: flex;
    flex-direction: column;
    padding-left: size.$size-4;

    .app-monitor-notifications-title {
        padding-top: size.$size-8;
    }

    .app-monitor-notifications-schedule {
        padding-left: size.$size-4;
    }

    .monitor-email-to {
        display: flex;
        flex-direction: column;
        gap: size.$size-4;
        margin-bottom: 0px;
        padding-bottom: size.$size-8;
    }

    .monitor-schedule-options {
        margin-bottom: 0px;

        .monitor-schedule-options-items {
            display: flex;
            flex-direction: column;
            gap: size.$size-4;
        }
    }

    .monitor-schedule-selection {
        padding: 0px 0px size.$size-8 size.$size-8;
    }
}
