@use 'css/vars/palette';
@use 'css/vars/size';

.setup-audit-trail-details {
	.subheading {
		color: palette.$gray-7;
	}

	.event-name {
		display: flex;
		padding: 5px 12px;
		width: max-content;
	}

	.more-info-link {
		padding: 5px 12px;
	}

	.condition-logic-selector {
		.ant-select-in-form-item {
			width: unset;
		}

		.ant-form-item {
			width: fit-content;
			margin-bottom: size.$size-8;
		}
	}

	.rule-grid {
		display: grid;
		gap: size.$size-8;
		align-items: center;
		grid-template-columns: 40px 224px 64px 224px auto;

		.ant-form-item {
			margin-bottom: 0px;
		}
	}

	.add-condition-button {
		width: fit-content;
	}
}
