@use 'css/vars/palette';
@use 'css/vars/size';

.monitors-table-card {
	> .ant-card-body {
		display: flex;
		flex-direction: column;
	}

	.filter-section {
		padding: size.$size-16 size.$size-24 size.$size-8 size.$size-24;

		.table-filters {
			display: flex;
			align-items: center;
			column-gap: size.$size-8;
			border-bottom: 1px solid palette.$gray-4;

			.ant-select,
			.monitor-name-input {
				width: 200px;
				align-items: start;
			}
		}
	}

	#monitors-table {
		.ant-table-header {
			overflow: visible !important;
		}

		.monitor-name {
			white-space: wrap;
		}
	}
}

.monitor-upsell-popover.ant-popover {
	.ant-popover-content {
		position: absolute;
		right: -30px; 
		
		.upsell-popover {
			width: 265px;
		}
	}
}
