@use 'sass:list';

$sizes: (2, 4, 8, 16, 24, 32, 64, 128);

$size-2: list.nth($sizes, 1) + px;
$size-4: list.nth($sizes, 2) + px;
$size-8: list.nth($sizes, 3) + px;
$size-16: list.nth($sizes, 4) + px;
$size-24: list.nth($sizes, 5) + px;
$size-32: list.nth($sizes, 6) + px;
$size-64: list.nth($sizes, 7) + px;
$size-128: list.nth($sizes, 8) + px;

$input-width: 384px;
$card-body-padding: $size-24;
$initiative-content-max-width: 800px;
