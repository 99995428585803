@use 'css/vars/palette';
@use 'css/vars/size';

.salesforce-button {
	border: 0;
	padding: 0;
	height: size.$size-24;

	.ant-space-item {
		height: size.$size-24;
	}
}

.manage-objects-popover {
	width: 325px;

	.manage-objects-confirm-message {
		color: palette.$gray-9;
	}
}
