@use 'css/vars/size';

.quick-access-views-placeholder {
	display: flex;
	flex-direction: column;
	row-gap: size.$size-8;
	padding-left: size.$size-32;
	padding-right: size.$size-8;
	width: 200px;

	.help-article-link {
		margin-left: size.$size-4;
	}
}
