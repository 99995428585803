@use 'css' as *;

#user-settings {
	.settings-panel {
		max-width: 360px;

		> * {
			padding-bottom: $size-24;
		}
	}
}
