@use 'css/vars/palette';
@use 'css/vars/size';

.salesforce-user-selector {
	&.vertical {
		.salesforce-user {
			width: 100%;
		}
	}

	.salesforce-user-label {
		display: block;
		max-width: 175px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.salesforce-user {
		width: 100%;

		:nth-child(2) {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
		.placeholder-avatar {
			color: palette.$gray-6;
			background-color: palette.$gray-3;
			border-color: palette.$gray-6;
		}

		.sonar-text {
			color: palette.$gray-6;
		}
	}
}

.salesforce-user-space-conscious-label {
	.ant-select-item {
		padding: size.$size-4;
	}

	.ant-select-item-option-content {
		.salesforce-user {
			width: 100%;

			.ant-space-item:nth-child(2) {
				width: 100%;
				overflow: hidden;

				.salesforce-user-label {
					display: block;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
