@use 'css/vars/size';

.table-modifier {
	display: flex;
	flex-wrap: nowrap;
	column-gap: size.$size-32;
	justify-content: space-between;

	> * {
		display: flex;
		flex-grow: 0;
	}

	.filters {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		row-gap: size.$size-8;
		column-gap: size.$size-8;
	}

	.sort-by-filter {
		display: flex;
		align-items: center;
		padding-left: size.$size-16;
		padding-right: size.$size-16;
		white-space: nowrap;
	}

	&.with-menu {
		.sort-by-filter {
			padding: 6px size.$size-16;
			display: inline-block;
		}
	}

	.arrangers {
		display: flex;
		flex-wrap: nowrap;
		justify-content: end;
		column-gap: size.$size-8;
		align-items: center;
	}
}

.filter-container {
	display: flex;
	flex-direction: column;
	flex: 1 1 160px;
	max-width: size.$input-width;

	.filter {
		width: 100%;
	}

	&.date {
		min-width: 256px;
	}
}
