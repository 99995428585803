@use 'css/vars/size';

.initiatives-table-action-bar {
    display: flex;
    justify-content: space-between;
    padding: size.$size-8;
    padding-bottom: 0;

    width: 100%;

    .initiatives-table-search {
        width: 300px;
    }
}
