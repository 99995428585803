@use 'css/vars/size';

#initiative-scope-summary {
    max-width: size.$initiative-content-max-width;

    .sub-section-children {
        gap: size.$size-16 size.$size-32;
        flex-wrap: wrap;

        > * {
            flex-shrink: 0;
        }
    }

    .scope-type-label:not(:hover) {
        color: unset;
    }
}
