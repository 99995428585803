@use 'css/vars/size';

.api-volume-card {
	.api-volume-graph {
		padding: 0 size.$size-24;
		padding-bottom: size.$size-16;

		.g2-tooltip {
			.g2-tooltip-title {
				font-weight: 700;
			}

			.g2-tooltip-name,
			.g2-tooltip-value {
				font-size: 14px;
			}
		}
	}
}
