@use 'css/vars/size';

.initiative-sub-section {
	.sub-section-header {
		display: flex;
		gap: size.$size-32;
		padding-top: size.$size-24;
		padding-bottom: size.$size-8;

		.sub-section-title {
			flex-shrink: 0;
		}

		.sub-section-children {
			display: flex;
		}

		&.reduced-padding {
			padding-top: size.$size-8;
		}

		.anticon {
			margin-right: size.$size-8;
		}
	}
}
