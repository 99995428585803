@use 'css/vars/palette';

.processing-icon {
	height: 20px;
	width: 20px;

	&.error {
		color: palette.$red-6;
	}

	&.success {
		color: palette.$green-6;
		animation: autohide 5s forwards;
	}

	@keyframes autohide {
		0%  { opacity: 0; }
		10%  { opacity: 1; }
		90% { opacity: 1; }
		100% { opacity: 0; }
	}
}
