@use 'css' as *;

.diagnostics-modal {
	.diagnostics-container {
		display: flex;
		justify-content: center;

		textarea {
			font-family: $code-font;
			width: 675px;
			height: 600px;
			margin: $size-16;
		}
	}
}
