@use 'css/vars/palette';
@use 'css/mixins';

.sonar-record {
	.secondary-info {
		@include mixins.text-regular(12);
		color: palette.$gray-8;
		text-align: right;
		align-self: center;
		white-space: normal;
		overflow-wrap: normal;
		overflow: hidden;
	}
}

