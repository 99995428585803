@use 'css/vars/palette';
@use 'css/vars/size';

.data-dictionary-filter-drawer {
	.ant-drawer-mask {
		background: none;
	}

	.ant-drawer-content-wrapper {
		top: 174px;
	}

	.ant-drawer-header {
		border-bottom: 0;
		padding: size.$size-16;

		.ant-drawer-header-title {
			flex-direction: row-reverse;
		}

		.ant-drawer-close {
			color: palette.$gray-10;
			margin-right: 0;
			font-size: 22px;
		}
	}

	.ant-drawer-body {
		padding: 0 size.$size-16 size.$size-16 size.$size-16;
	}

	.filters {
		display: flex;
		flex-direction: column;
		row-gap: size.$size-8;

		.filter-container {
			flex: auto;
		}
	}
}

.filter-menu-button {
	&.active {
		color: palette.$blue-6;
	}
}
