@use 'css/vars/size';

.detected-applications {
	.ant-card-body {
		padding-top: 0;
	}

	&.empty {
		.ant-card-body {
			text-align: center;
		}
	}

	.detected-applications-list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
		grid-gap: size.$size-24;
		padding: 0 size.$size-8;
	}
}
