@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

#search {
	display: flex;
	padding-left: 28px;
	padding-right: 28px;
}

.search-collapsed-icon {
	@include mixins.text-regular(16);
	color: palette.$gray-6;
	justify-content: center;
	padding: size.$size-8;
	width: 100%;
}
