@use 'css' as *;

.preference-toggles {
	.ant-form-item {
		display: flex;

		.ant-form-item-label {
			display: flex;
			flex: 3;
		}
	}
}
