@use 'css/vars/palette';
@use 'css/vars/size';

.access-statistics-row {
	display: grid;
	grid-template-columns: 1fr 65px;
	column-gap: size.$size-8;
	align-items: center;
	cursor: pointer;

	&:not(.clickable) {
		cursor: default;
	}

	.ant-tag {
		display: flex;
		height: 24px;
		justify-content: start;
		margin-inline: size.$size-2;
		margin-inline-end: unset;
		border: transparent 1px;
	}

	&.clickable:hover {
		.ant-tag {
			border: solid 1px;
		}

		.sonar-text {
			font-weight: 600;
		}
	}

	.name-bar {
		display: flex;
		position: relative;
		align-items: center;
		overflow: hidden;

		.stat-details {
			display: flex;
			position: absolute;
			max-width: 100%;
			padding-left: size.$size-8;
			column-gap: size.$size-8;
			overflow: hidden;
			text-overflow: ellipsis;

			.sonar-text {
				text-wrap: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&.overflow {
				position: relative;
				padding-left: size.$size-4;
			}
		}
	}

	.request-count {
		flex-grow: 1;
		text-align: right;
		text-wrap: nowrap;
	}
}
