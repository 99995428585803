@use 'css/vars/size';

.timeline-filters {
	display: flex;
	flex-direction: column;
	row-gap: size.$size-16;
	max-width: 250px;

	.entity-changes {
		display: block;
		padding-bottom: size.$size-8;
	}

	.display-name-filter {
		padding-top: size.$size-4;
	}

	.system-changes-toggle {
		padding-bottom: size.$size-16;
	}
}
