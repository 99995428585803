@use 'css/vars/palette';
@use 'css/vars/size';

.file-drop-area {
	background: palette.$gray-2;
	border: 1px dashed palette.$gray-5;
	border-radius: size.$size-4;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 240px;
	justify-content: center;
	text-align: center;
	transition: border-color 0.3s;

	&:hover {
		border-color: palette.$blue-5;
	}

	.upload-icon {
		color: palette.$blue-5;
		font-size: 36px;

		&.invalid {
			color: palette.$red-6;
		}

		&.valid {
			color: palette.$green-7;
		}
	}
}
