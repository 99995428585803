@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.initiative-scope-table-empty-message {
	margin: auto;
	max-width: 400px;
}

.initiative-scope-table {
	height: auto;

	&.selection-table {
		margin-top: size.$size-8;
	}

	.section-header {
		@include mixins.text-semibold(20);
		display: block;
		padding-top: size.$size-24;
		padding-bottom: size.$size-8;
	}

	.ant-table-selection-column {
		vertical-align: middle;
	}

	th.ant-table-column-sort {
		background-color: palette.$gray-2;
	}

	th.ant-table-column-has-sorters:hover {
		background-color: palette.$gray-2;
	}
}
