@use 'css/vars/size';

#main-menu {
	user-select: none;
}

.sidebar-search-entry {
	cursor: unset !important;
	height: auto !important;
	padding-top: size.$size-4;
	padding-bottom: size.$size-4;
	padding-left: size.$size-8 !important;
	padding-right: size.$size-8 !important;
}