@use 'css' as *;

.tag-name-input {
	& + div {
		.ant-popover-inner-content {
			padding: 0 !important;
			margin-bottom: 1px;
		}
	}

	.hex-color-circle {
		border-radius: $size-4;
		border: 1px solid $gray-1;
		width: 12px;
		height: 12px;
	}
}
