@use 'css/vars/palette';
@use 'css/vars/size';

.visual-picker {
    margin-bottom: 0;

    .visual-picker-group {
        gap: size.$size-8;
        padding: size.$size-8 0;

        .visual-picker-group-item {
            border-radius: 0.25rem;

            &:hover {
                border-color: palette.$blue-6 !important;    
            }

            .visual-picker-group-item-title {
                
                display: flex;
                text-align: center;    }    
        }

        .visual-picker-checkmark-area {
            position: absolute;
            top: 0.3rem;
            right: 0.5rem;
            width: 0.5rem;
            height: 0.5rem;
            z-index: 1;

            .visual-picker-checkmark-icon {
                color: palette.$gray-1
            }
        }

        .ant-radio-button {
            z-index: 1;
        }

        .ant-radio-button-wrapper {
            border: 1px solid palette.$gray-5;

            &:before {
                width: unset !important;
            }    
        }

        .ant-radio-button-wrapper-checked {
            border-color: palette.$blue-6 !important;
        }

        .ant-radio-button-checked{
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 .20rem 0 0;
                border-left: 1rem solid transparent;
                border-bottom: 1rem solid transparent;
                border-right: 1rem solid transparent;
                border-right-color: palette.$blue-6;
                border-top: 1rem solid transparent;
                border-top-color: palette.$blue-6;
            }
        }
    }
}
