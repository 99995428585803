@use 'css/vars/palette';
@use 'css/vars/size';

.access-statistics {
	.ant-card-body {
		padding-top: 0px;
	}

	.access-statistics-table {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		gap: size.$size-32;
	}

	.access-statistics-legend {
		padding-top: size.$size-8;
	}

	.ant-tag.color-bar {
		border: transparent;
		background-color: palette.$gray-5;

		&.deleted {
			background-color: palette.$gray-4;
		}

		&.blue {
			background-color: palette.$blue-2;
		}

		&.green {
			background-color: palette.$green-2;
		}

		&.gold {
			background-color: palette.$gold-2;
		}

		&.volcano {
			background-color: palette.$volcano-2;
		}

		&.red {
			background-color: palette.$red-2;
		}

		&.magenta {
			background-color: palette.$magenta-2;
		}
	}
}
