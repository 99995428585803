@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

#initiative-view {
	.add-task-input {
		margin-top: size.$size-8;
		margin-bottom: size.$size-8;
	}

	.ant-comment-avatar {
		cursor: default;
	}

	.ant-form-item {
		margin-bottom: 0;
	}

	.task {
		background-color: palette.$gray-3;
		padding: size.$size-8;
		border: 0 dashed palette.$gray-6;

		> .ant-row {
			> .ant-col:first-child {
				> .ant-space {
					width: 100%;

					> .ant-space-item:last-child {
						width: 100%;
					}
				}
			}
		}

		.ant-checkbox-wrapper {
			width: 100%;
		}

		.ant-typography {
			cursor: text;
			margin-bottom: 0;
			overflow-wrap: anywhere;

			&.ant-typography-edit-content {
				margin-left: size.$size-8;
				margin-top: -4px;
				width: 100%;

				.ant-input {
					max-width: 100%;
					width: 100%;
				}
			}

			&.task-complete {
				text-decoration: line-through;
				color: palette.$gray-7;
			}
		}

		.task-action-area {
			align-self: flex-start;

			&:not(.ant-col-0) {
				display: flex;
			}
		}

		.task-icon-area {
			visibility: hidden;
			text-align: right;

			.anticon {
				@include mixins.text-regular(16);
				margin-right: size.$size-4;
			}
		}

		.scope-item {
			margin-left: size.$size-32;
			margin-top: size.$size-8;
		}

		&.adding-scope {
			border-width: 1px;
			padding: size.$size-8;
		}

		&.dragging,
		&:hover {
			background-color: palette.$gray-1;

			.task-icon-area {
				visibility: initial;
			}
		}

		.user-selector {
			float: right;
		}
	}
}
