@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';

.code-comparison {
	.code {
		font-family: text.$code-font;
		font-weight: text.$regular;
		overflow-x: auto;
		overflow-wrap: break-word;
		white-space: normal;

		.title-diff {
			padding-left: size.$size-8;
		}
	}

	.added {
		color: colors.$addition;
	}

	.removed {
		color: colors.$deletion;
	}

	.diff-text {
		color: palette.$gray-13;

		&.addedFromOld,
		&.removedFromNew {
			user-select: none;
			display: none;
		}

		&.addedToNew,
		&.removedFromOld {
			color: palette.$gray-10;
			border: 1px solid transparent;
			border-radius: size.$size-4;
			padding-left: size.$size-2;
			padding-right: size.$size-2;
		}

		&.addedToNew {
			border-color: palette.$green-2;
			background-color: palette.$green-2;
		}

		&.removedFromOld {
			border-color: palette.$red-2;
			background-color: palette.$red-2;
		}
	}
}
