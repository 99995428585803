@use 'css' as *;

.placeholder-avatar {
	background-color: transparent;
	border: 1px dashed #262626;
	color: black;
}

.sonar-avatar {
	user-select: none;
	flex: 0 0 auto;

	&.large {
		height: $size-128;
		width: $size-128;
		line-height: $size-128;
		font-size: $size-64;
	}
}
