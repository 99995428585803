@use 'css/vars/palette';
@use 'css/vars/size';

.filter-container {
	.compliance-categories .anticon > * {
		height: size.$size-24;
		padding: 0 size.$size-4;
	}

	.ant-select-selector .sonar-text {
		margin-left: size.$size-8;
	}
}
