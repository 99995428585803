.periscope-slide-enter {
	opacity: 0.5;
	transform: translateX(-1520px);

	&.periscope-slide-enter-active {
		opacity: 1;
		transform: translateX(0);
		transition: 0.5s;
	}
}

.periscope-slide-appear {
	opacity: 0.5;
	transform: translateX(-520px);

	&.periscope-slide-appear-active {
		opacity: 1;
		transform: translateX(0);
		transition: 0.5s;
	}
}

.periscope-slide-leave {
	display: none;

	&.periscope-slide-leave-active {
		opacity: 0;
		transform: translateX(-520px);
		transition: 0.5s;
	}
}
