@use 'css/vars/size';

.monitored-objects {
	display: flex;
	gap: 32px;

	.data-security-api-requests {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		gap: size.$size-8;
	}
}
