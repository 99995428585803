@use 'css/vars/palette';

#initiative-view {
	.ant-space-vertical {
		width: 100%;
	}

	.sonar-card {
		> .ant-card-body {
			background-color: palette.$gray-3;
			position: relative;
		}
	}
}
