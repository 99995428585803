@use 'css/vars/palette';
@use 'css/vars/size';

.user-changes-chart {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: size.$size-8;

	.chart {
		canvas {
			cursor: pointer !important;
		}
	}

	.chart-tooltip {
		padding: size.$size-8;
	}

	.user-avatars {
		display: flex;
		gap: 3px;
		padding: size.$size-2;

		.sonar-user,
		.salesforce-user {
			width: fit-content;
		}

		.ant-avatar {
			width: 25px;
			height: 25px;

			.ant-avatar-string {
				font-size: 12px;
				line-height: 25px;
			}
		}
	}
}
