@use 'css/vars/palette';
@use 'css/vars/size';

.add-app-monitor-section {
    padding: size.$size-8 size.$size-16;
    display: flex;
    flex-direction: column;
    gap: size.$size-8;

    .monitor-form {
        background-color: inherit;
        display: flex;
        flex-direction: column;
        gap: size.$size-8;
    }

    .monitor-upsell {
        padding: 16px;
        gap:16px;
        align-items: flex-start;
        border-radius: 2px;
        border-radius: 1px solid palette.$gray-5;
        background: palette.$gray-1;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

        .ant-space-item {
            align-self: stretch;
            display: flex;
            flex-direction: column;
        }
        
        .monitor-upsell-icon {
            align-self: center;
        }

        .contact-us-button {
            align-self: center;
        }
    }
}
