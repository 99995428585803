@use 'css/vars/size';
@use 'css/vars/palette';

.action-template-card {
	height: 100%;
	min-width: 256px;

	&.disabled {
		filter: grayscale(65%);
		cursor: not-allowed;
		background: palette.$gray-2;
	}

	> .ant-card-body {
		display: flex;
		gap: 0;
		height: 100%;
		padding: 0;

		.template-icon-area {
			border: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			font-size: size.$size-24;
			margin: 0;
			padding: size.$size-16;
		}

		.action-template-card-content {
			padding: size.$size-24 size.$size-16;
		}

		.action-template-label {
			width: fit-content;
		}
	}
}
