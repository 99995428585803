@use 'css' as *;

.feature-flag-search {
    width: 600px;
    display: block;
    margin-bottom: $size-16;
}

.feature-flag-manager {
    .ant-checkbox-group-item {
        width: 300px;
        margin-top: $size-8;
        margin-bottom: $size-8;
    }
}
