@use 'css/vars/palette';
@use 'css/vars/size';

.suggest-field {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;

	.suggestion-overlay {
		border: 1px solid transparent;
		border-radius: size.$size-4;
		cursor: text;
		padding: size.$size-8;
		height: 100%;
		grid-row-start: 1;
		grid-column-start: 1;

		.placeholder {
			color: palette.$gray-9;
		}

		&.spreadsheet {
			z-index: 101;
		}

		&:not(.spreadsheet) {
			border-color: palette.$gray-5;
			border-style: dotted;
			border-width: 2px;
			padding: 7px;
			background-color: palette.$gray-2;

			&:hover {
				border-style: solid;
				border-width: 1px;
				border-color: palette.$gray-10;
				padding: 8px;
			}
		}

		&:hover {
			border-color: palette.$gray-10;
		}

		.suggestion-preview {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			.suggestion-text {
				display: flex;
				flex-wrap: wrap;
				line-height: 18px;

				span {
					padding: size.$size-2;
					background-color: palette.$blue-2;
				}
			}

			.accept-suggestion {
				align-self: center;
				padding-top: size.$size-8;

				.ant-btn {
					padding: size.$size-4;

					span {
						text-decoration: underline;
					}
				}
			}
		}

		.suggestion-button {
			float: left;
			position: absolute;
			top: 6px;
			left: 3px;
		}

		&.error {
			border-color: palette.$red-7;

			.error-icon {
				opacity: 1;
			}
		}

		.error-icon {
			display: flex;
			position: absolute;
			align-items: center;
			justify-content: end;
			bottom: 11px;
			right: size.$size-8;
			color: palette.$red-7;
			width: 50px;
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
		}

		.ant-skeleton {
			cursor: not-allowed;
			width: 100%;
			height: 100%;

			.ant-skeleton-input {
				width: 100%;
				height: 100%;
				min-height: 22px;
			}
		}
	}

	.suggestion-editor {
		height: 100%;
		grid-row-start: 1;
		grid-column-start: 1;

		&.hidden {
			color: transparent !important;
		}
	}
}
