@use 'css/vars/size';

#data-security-view-container {
	.data-security-view-header {
		width: 100%;

		.ant-card-head {
			padding: size.$size-16 size.$size-24;
		}

		.ant-card-body {
			display: none;
		}
	}
}
