@use 'css/vars/palette';
@use 'css/vars/size';

.edit-box {
	cursor: text;
	width: fit-content;
	min-width: -webkit-fill-available;
	max-width: 100%;
	border: 1px solid transparent;
	border-radius: size.$size-4;
	padding: size.$size-8;

	.content {
		display: flex;
		flex-direction: column;
		position: relative;
		flex-grow: 1;
		height: 100%;
	}

	&.spreadsheet {
		height: 100%;
		width: 100%;
	}

	&:focus-within {
		border-color: palette.$blue-6;
	}

	&.empty {
		background-color: palette.$gray-2;
		border-color: palette.$gray-5;
		border-style: dotted;
		border-width: 2px;
		padding: 7px;

		&:hover,
		&:focus-within {
			border-style: solid;
			border-width: 1px;
			padding: size.$size-8;
		}

		&:hover {
			border-color: palette.$gray-10;
		}

		&:focus-within {
			border-color: palette.$blue-6;
		}
	}

	&.readonly {
		cursor: default !important;
		border: 1px solid transparent !important;

		&:not(.readonly-hover) {
			pointer-events: none;
		}

		.ant-select-clear,
		.ant-select-arrow {
			display: none;
		}
	}

	.activity-indicators {
		align-items: end;
		justify-content: end;
		position: absolute;
		font-size: size.$size-16;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: row;
		gap: 0;
		pointer-events: none;
		margin-right: -(size.$size-4);
		margin-bottom: -(size.$size-4);

		.edit-icon {
			position: absolute;
			visibility: hidden;
			top: 0;
			right: 0;
		}

		.processing-icon {
			z-index: 100;

			&.error {
				pointer-events: auto;
			}
		}
	}

	&:hover {
		&:not(.validation-error):not(.processing-error):not(:focus-within) {
			border-color: palette.$gray-10;
		}

		.edit-icon {
			visibility: visible;
		}
	}

	&.validation-error,
	&.processing-error {
		border-color: palette.$red-5;
	}
}
