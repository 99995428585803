@use 'css' as *;

.field-update-details {
	.sonar-record {
		margin: 0px;
	}

	.periscope-code {
		margin: $size-2 $size-4;
	}
}

