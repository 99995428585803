@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.timeline-record {
    &:not(:last-of-type) {
        margin-bottom: size.$size-8;
    }

    .timeline-record-expand {
        margin-left: size.$size-8;
    }

    .timeline-record-icon {
        margin-right: size.$size-8;
    }

    .timeline-record-label {
        @include mixins.text-semibold(14);
    }

    .timeline-record-timestamp {
        padding-left: size.$size-16;
    }

    .timeline-record-diff-row {
        background: palette.$gray-2;
        padding: size.$size-8 size.$size-16;
        margin-bottom: -(size.$size-8);
        margin-left: -(size.$size-16);
        margin-right: -(size.$size-16);
    }
}
