@use './reset';
@use './antd-overwrites';
@use 'css/vars/size';
@use 'css/mixins';

.visually-hidden {
	@include mixins.visually-hidden();
}

.display-none {
	display: none !important;
}

.align-bottom {
	align-self: flex-end;
}

.align-right {
	text-align: right;
}

.clickable {
	cursor: pointer;
}

.scrollable {
	overflow-y: auto;
}

.card-subheading {
	display: block;
	font-weight: bold;
	padding: size.$size-24 size.$size-8 size.$size-4 size.$size-8;
 }

.animate {
	transition-timing-function: cubic-bezier(0.5, 0.5, 0.25, 1);
	transition-duration: 0.4s;
	transition-property: all;
}

.inline-form-with-overhead-labels {
	position: relative;
	padding-top: size.$size-32;
	height: 80px;

	.ant-form-item-label {
		position: absolute;
		text-align: left;
		top: 0px;
	}
}

td.no-padding-cell {
	padding: 0 !important;
}

td.center-align-cell {
	text-align: center;
}
