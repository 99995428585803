@use 'css/vars/palette';
@use 'css/vars/size';

#jira-integration {
	height: 100%;

	.ant-card-body {
		overflow-y: overlay;
	}

	.jira-container {
		max-width: 800px;
	}
	
	.jira-integration-card-title {
		display: flex;
		align-items: center;

		.jira-icon {
			color: palette.$blue-6;
			margin-right: size.$size-8;
			font-size: size.$size-24;
		}
	}

	.integration-copy {
		display: block;
		padding-bottom: size.$size-16
	}
}

.jira-help-url {
	a {
		display: block;
		color: palette.$blue-2;
	}
}
