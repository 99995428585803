@use 'css/vars/size';

.view-wrapper {
	box-sizing: border-box;
	display: flex;
	flex: 1;
	padding: size.$size-8;
	column-gap: size.$size-8;
	row-gap: size.$size-8;
	max-width: 75%;

	@media (max-width: 1200px) {
		max-width: 100%;
	}

	&.full-width {
		max-width: 100%;
	}

	&.full-height {
		max-height: 100%;
		min-height: 100%;
	}

	&.no-padding {
		padding: 0px;
	}

	&.view-horizontal {
		> .view-area {
			> .sonar-card:first-of-type {
				height: 100%;

				> .ant-card-body {
					display: flex;
					flex-direction: column;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}

			> .sonar-card-tabs {
				height: 100%;

				.ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
					display: flex;
					flex-direction: column;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}
		}
	}

	&.view-vertical {
		&:not(.full-height) {
			height: fit-content;
		}
	}

	&.only-one-periscope {
		flex-grow: 2;
	}

	.view-area {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex: 1 1;
		height: 100%;
		overflow: hidden;
		width: 0px;

		&.periscope-hidden {
			margin-right: -(size.$size-8);
		}
	}
}

.periscope-view-wrapper {
	position: sticky;
	top: 0;

	.view-wrapper:first-of-type {
		height: 100%;
		padding-left: 0px;
	}
}
