@use 'css/vars/size';

.change-records-filter {
	.ant-divider-horizontal {
		margin-top: 0px;
		margin-bottom: size.$size-8;
	}

	.sonar-button {
		display: block;
		margin: auto;
	}
}
