@use 'css' as *;

.detail-section {
    &:not(:first-child) {
        margin-top: $size-16;
    }

    &:last-child {
        margin-bottom: $size-16;
    }

    .detail-section-header {
        padding: $size-4 $size-8;
        user-select: none;
    }

    .detail-section-body {
        padding: 0 $size-8;
    }

    .detail-section-body-small-spacing {
        padding: 0 $size-4;
    }

    .detail-section-body-large-spacing {
        padding: 0 $size-16;
    }

    .detail-section-body-no-spacing {
        padding: 0;
    }
}
