@use 'css/vars/size';

.notifications-card {
	max-height: 500px;
	overflow: auto;

	> .ant-card-body {
		overflow-y: overlay;
	}
}
