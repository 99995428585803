@use 'css/vars/size';

.paginated-table {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.ant-table-wrapper {
		height: 100%;
		overflow: hidden;
	}

	.ant-table,
	.ant-table-body,
	.ant-table-container,
	.ant-spin-container,
	.ant-spin-nested-loading {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.ant-table-container {
		display: contents;
	}

	&.isEmpty .ant-table-header > table {
		width: -webkit-fill-available;
	}

	.action-bar {
		padding-bottom: size.$size-24;
	}

	.ant-table:not(.ant-table-scroll-horizontal) {
		.ant-table-header {
			overflow: visible !important;
		}
	}

	&.small-header {
		th {
			padding-top: 8px !important;
			padding-bottom: 8px !important;
		}
	}

	.ant-table-wrapper {
		height: 100%;
		overflow: hidden;
	}

	.ant-table {
		background: transparent;
	}

	.ant-table-body {
		overflow: auto !important;

		.table-actions {
			overflow: visible;
		}
	}

	.ant-table-sticky-holder {
		background-color: transparent;
	}

	.simple-pagination {
		border-top: 1px solid #f0f0f0;
	}
}
