@use 'css/vars/colors';
@use 'css/vars/text';
@use 'css/mixins';

html {
	@include mixins.text-regular(16);
	font-family: text.$default-font;
}

body {
	margin: 0;
	padding: 0;
	font-family: text.$default-font;
	background: colors.$gateway-background;
	overflow: hidden;
	background-color: colors.$app-background !important;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
