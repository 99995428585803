@use 'css/vars/size';

.inline-input-form {
    padding: 10px size.$size-8;

    > div {
        width: 100%;

        > :first-child {
          width: 100%;  
        }
    }

    &.no-padding {
      padding: 0;
    }
}
