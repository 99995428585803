@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';

.tag-submenu {
	.manager-link {
		color: inherit;
	}

	.tag-search-entry {
		cursor: unset !important;
		height: auto !important;
		padding-top: size.$size-4;
		padding-bottom: size.$size-4;
		padding-left: size.$size-8 !important;
		padding-right: size.$size-8 !important;
	}

	#tag-search {
		display: flex;
		padding-left: 28px;
		padding-right: 28px;
	}

	.tag-submenu-item {
		display: flex;
		align-items: center;
	}
}
