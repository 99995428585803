@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.toolbar {
	margin-bottom: size.$size-4;

	> div {
		box-shadow: none;
	}

	.emoji-select {
		display: inline-block;

		button {
			@include mixins.text-regular(20);
			background: palette.$gray-2;
			border: 0;
			vertical-align: bottom;
			height: 34px;
			width: 36px;
		}
	}

	button {
		cursor: pointer;
	}

	.grey-emoji {
		filter: saturate(0.01);
	}

	.hTagButtons {
		display: inline-block;

		button {
			font-weight: text.$semibold;
			padding: 0px;
		}
	}
}
