@use 'css' as *;

.periscope-view {
	height: 100%;
	width: 100%;

	.periscope-list {
		display: flex;
		flex-direction: column;
		row-gap: $size-8;
		height: 100%;
		overflow-x: hidden;

		.periscope-container {
			display: flex;
			flex-direction: row;
			column-gap: $size-8;
			height: 100%;
			overflow-x: hidden;
		}
	}
}
