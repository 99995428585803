@use 'css/vars/palette';

#application-overview-card {
	.back-to-overview-button {
		color: palette.$gray-7;
		display: flex;
		padding: 0;
		width: fit-content;
		height: fit-content;
	}

	.ant-card-body {
		padding-top: 0;
	}
}
