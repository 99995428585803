@use 'css/vars/size';
@use 'css/mixins';

.inline-metadata {
    .detail-subsection-title {
        @include mixins.text-semibold(14);
    }

    .anticon {
        display: flex;
        font-size: 18px;
    }
}

.inline-metadata + .inline-metadata {
    margin-top: size.$size-8;
}
