.sonar-modal-popover {
    position: fixed;

    &.sonar-modal-popover-bottom-left {
        transform-origin: top left !important;
    }

    &.sonar-modal-popover-bottom-right {
        transform-origin: top right !important;
    }

    &.sonar-modal-popover-top-left {
        transform-origin: bottom left !important;
    }

    &.sonar-modal-popover-top-right {
        transform-origin: bottom right !important;
    }
}
