@use 'css/vars/size';

.bulk-add-popover {
	&.disabled-popover {
		width: 265px;

		.ant-popover-inner {
			padding-bottom: 1px;

			.ant-popover-inner-content {
				margin: size.$size-16;
			}
		}
	}
}
