@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

.draft {
	position: relative;

	&.isForm,
	&.active,
	&.empty {
		.DraftEditor-root {
			padding: size.$size-8;
			cursor: unset;
		}
	}

	.edit-icon {
		display: none;
		float: right;
		position: absolute;
		font-size: size.$size-16;
		top: 11px;
		right: size.$size-8;
		width: auto;
	}

	&:not(.empty):not(.active):not(.isReadOnly):hover {
		.edit-icon {
			display: unset;
		}
	}

	&.bordered {
		.DraftEditor-root {
			border-color: palette.$gray-5;
			border-radius: size.$size-4;
			border-style: solid;
			border-width: 1px;

			.public-DraftEditorPlaceholder-inner {
				color: palette.$gray-6;
			}
		}

		&.active {
			.DraftEditor-root {
				border-color: palette.$blue-6;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: 0;
			}

			.toolbar > div {
				border-bottom-color: palette.$blue-6;
				border-left-color: palette.$blue-6;
				border-right-color: palette.$blue-6;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}

	&.active {
		.DraftEditor-root {
			background-color: palette.$gray-1;

			.public-DraftEditor-content {
				background-color: palette.$gray-1;
			}
		}
	}

	&.editOnClick:not(.active) {
		cursor: text;
	}

	&.isReadOnly {
		cursor: not-allowed !important;
	}

	.public-DraftEditorPlaceholder-root {
		pointer-events: none;
		position: relative;

		.public-DraftEditorPlaceholder-inner {
			position: absolute;
		}
	}

	.public-DraftEditor-content {
		padding: 0px;

		.public-DraftStyleDefault-block {
			margin: 0px;
		}

		h1,
		h2,
		h3 {
			margin: 0px;
		}

		h1 {
			@include mixins.text-regular(24);
		}
		h2 {
			@include mixins.text-semibold(20);
		}
		h3 {
			@include mixins.text-semibold(16);
		}

		a {
			color: palette.$blue-6;
		}
	}

	.action {
		margin: size.$size-4 size.$size-4 0px 0px;
	}

	.mention {
		font-weight: text.$semibold;
		color: palette.$blue-6;
	}
}

.editor-popover {
	@include mixins.elevated();

	z-index: 101;

	&.ant-popover-placement-bottom {
		padding-top: 0;
	}

	&.ant-popover-placement-top {
		padding-bottom: 0;
	}

	.ant-popover-arrow {
		display: none;
	}

	.ant-popover-inner-content {
		padding: 0;
	}

	.toolbar {
		margin-bottom: 0;
		margin-top: -3px;
		overflow: hidden;

		> div {
			border-width: 0;
		}
	}
}

.outside-click {
	height: 100%;
}
