@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.team-invites {
    .processed-indicator {
        @include mixins.text-regular(24);
        color: palette.$blue-6;
        padding-top: size.$size-8;
    }

    p {
        @include mixins.text-regular(14);
        padding-top: size.$size-8;
        text-align: center;
    }
}
