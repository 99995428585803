@use 'css/vars/size';
@use 'css/mixins';

.periscope-type-label,
.periscope-subtype-label {
    display: inline-block;
}

.periscope-subtype-label {
    @include mixins.text-regular(14);
    padding-left: size.$size-8;
}
