@use 'css' as *;

#oidc-providers {
	border-top: 1px solid  $gray-4;
	padding: $size-24 0px;

	.providers > div,
	.subtext {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.providers {
		> div {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0px $size-24;
			margin-bottom: $size-8;

			&:last-child {
				margin-bottom: 0px;
			}

			button {
				margin: 0px $size-24;
				width: 100%;
			}
		}
	}

	.subtext {
		padding-bottom: $size-8;
	}
}
