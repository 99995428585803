@use 'css/vars/size';

.uptime-window {
    flex-direction: column;
    align-items: flex-start;
    gap: size.$size-4;

    .uptime-window-title {
        display: flex;
        gap: size.$size-4;
    }

    .uptime-window-options {
        padding-left: size.$size-8;
        margin-bottom: 0px;

        .uptime-window-options-items {
            display: flex;
            flex-direction: column;
            gap: size.$size-4;
        }
    }

    .uptime-weekdays-trigger {
        padding: size.$size-8 0px 0px size.$size-4;
        margin-bottom: 0px;

        .ant-form-item-control-input {
            min-height: auto;
        }
    }
}
