@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';

.periscope-tab {
	height: 100%;
	overflow: hidden;

	> .ant-tabs-nav {
		margin-bottom: 0;
		padding-left: size.$size-8;

		.ant-tabs-nav-wrap,
		.ant-tabs-nav-operations {
			background: palette.$gray-1;
		}

		> .ant-menu {
			line-height: 38px;

			.ant-menu-item,
			.ant-menu-submenu {
				padding: 0 10px;

				&.ant-menu-item-active,
				&.ant-menu-submenu-selected {
					color: palette.$blue-6;
					font-weight: text.$semibold;
				}
			}
		}
	}

	.ant-tabs-tabpane {
		flex-direction: column;
		height: 100%;
		padding-top: size.$size-4;
		overflow-y: auto;

		&.ant-tabs-tabpane-active {
			display: flex;
		}
	}
}
