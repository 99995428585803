@use 'css' as *;

.paginated-list {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.list-paginator {
	padding: $size-8 $size-4 $size-4 $size-4;
	text-align: right;

	.ant-pagination-item {
		display: none;
	}
}
