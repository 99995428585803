@use 'css/vars/size';

.data-dictionary-search-bar {
    flex-wrap: nowrap;
    margin-bottom: size.$size-16;

    > :last-child {
        margin-left: auto;
    }
}
