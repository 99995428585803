.sonar-modal-dropdown-root {
	width: fit-content;
	height: 0px !important;
	width: 0px !important;

	.ant-modal-wrap {
		overflow: visible;
		position: relative;
	}

	.sonar-modal-dropdown {
		position: absolute;
		height: fit-content;
		top: unset;
		margin: 0;

		.ant-modal-content {
			min-width: 200px;
		}

		&.sonar-modal-dropdown-bottom-left {
			transform-origin: top left !important;
		}

		&.sonar-modal-dropdown-bottom-right {
			transform-origin: top right !important;
		}

		&.sonar-modal-dropdown-top-left {
			transform-origin: bottom left !important;
		}

		&.sonar-modal-dropdown-top-right {
			transform-origin: bottom right !important;
		}
	}
}
