@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.related-tag {
	display: inline-flex;
	align-items: center;

	.ant-tag {
		@include mixins.text-regular(12);
		margin: 0px size.$size-4;
		padding: 0px size.$size-4;
		box-shadow: 0px 1px 1px palette.$gray-5;
	}

	&.salesforce {
		.ant-tag {
			color: palette.$blue-6;
			border: 1px solid palette.$blue-6;
			background: palette.$blue-1;
		}
	}
}
