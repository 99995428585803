@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';

.api-requests {
	align-items: flex-end;
	display: flex;
	flex-direction: column;

	.api-header {
		color: palette.$gray-7;
		display: flex;
		font-weight: text.$semibold;
		height: 28px;
		align-items: center;
		gap: size.$size-8;
	}

	.api-data {
		display: flex;
		align-items: baseline;
		gap: size.$size-4;
	}

	.used-calls {
		font-size: 20px;
		font-weight: text.$semibold;
	}

	.max-calls {
		color: palette.$gray-8;
		font-size: 14px;
		font-weight: text.$regular;
	}

	.api-progress {
		padding: size.$size-4 0px;
		margin-bottom: 0;
		margin-right: 0;
		width: 192px;
	}

	.ant-progress-text {
		color: palette.$gray-7;
	}
}
