@use 'css/vars/size';

.maintenance-view {
	width: 780px;

	.maintenance-image {
		background-image: url('../../../images/maintenance.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 500px;
		display: block;
		margin: auto;
		margin-bottom: size.$size-16;
		height: 300px;
		width: 450px;
	}
}
