@use 'css/vars/size';

.salesforce-compliance-category-selector:not(.spreadsheet) {
	.ant-select-multiple {
		.ant-select-selector {
			height: size.$size-24;
		}

		.ant-select-selection-overflow {
			column-gap: size.$size-2;
		}

		.ant-select-selection-item {
			padding-right: 0;
			margin-bottom: 0;
			margin-top: 0;

			> span {
				margin-right: 0;
			}
		}

		.ant-select-clear,
		.ant-select-arrow {
			inset-inline-end: -10px;
			top: 15px;
		}

		.anticon > img {
			height: size.$size-24;
		}
	}

	.activity-indicators {
		inset-inline-end: -14px;
	}
}