@use 'css' as *;

.admin-user-controls {
    button {
        margin-right: $size-8;
    }

    .ant-btn-group {
        margin-right: $size-8;

        button {
            margin-right: 0px;
        }
    }

    .unlock-user-button {
        width: 100px;
    }

    .lock-user-button {
        width: 100px;
    }
}
