@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.popover-selector {
	display: flex;
	padding: 0px;
}

.popover-selector-panel {
	@include mixins.text-regular(12);
	color: colors.$default-black;
	max-width: 330px;

	&.ant-tooltip-placement-bottomRight {
		padding-top:  0px;
	}

	.ant-tooltip-inner {
		padding: 0px;
		background: palette.$gray-1;
	}

	.ant-tooltip-content {
		width: calc(100% + 1px);
	}

	.popover-header {
		display: flex;
		padding: size.$size-8;
		column-gap: size.$size-8;
		border-bottom: 1px solid palette.$gray-4;
		align-items: center;

		.manager-link {
			display: flex;

			.anticon-setting {
				font-size: size.$size-16;
				color: colors.$default-black;
			}
		}
	}

	.selector-list {
		border-bottom: 1px solid palette.$gray-4;
		max-height: 300px;
		overflow-y: auto;

		.clickable-row {
			align-items: center;
			border-bottom: 1px solid palette.$gray-4;
			color: colors.$default-black;
			cursor: pointer;
			display: flex;
			overflow: hidden;
			padding: 9px size.$size-16;
			line-height: 1em;

			&:last-child {
				border-bottom-color: transparent;
			}

			.selected-check {
				color: palette.$blue-5;
			}

			&:hover {
				background-color: palette.$gray-3;

				.open-item {
					display: inherit;
					margin-left: size.$size-16;
				}
			}

			&.selected {
				background-color: palette.$blue-1;
			}

			.open-item {
				display: none;
				margin-left: size.$size-16;
			}

			.list-item {
				display: flex;
				background: transparent;
				border: 0;
				border-radius: 0;
				box-shadow: none;
				flex: 1;
				justify-content: flex-start;
				margin: 0;
			}
		}
	}
}
