@use 'css/vars/size';

.quick-add-button-wrapper {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: size.$size-8 size.$size-16;

    &.no-padding {
        padding: 0;
    }
}
