@use 'css/vars/palette';
@use 'css/vars/size';

.app-status-table {
	.ant-card-head {
		border-bottom: none;
	}

	.ant-table .ant-table-thead > tr > th {
		padding: size.$size-8;
	}

	colgroup col:nth-child(4),
	td.ant-table-cell-scrollbar {
		display: none;
	}
}
