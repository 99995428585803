@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/vars/text';
@use 'css/mixins';

#timeline-view {
	> .sonar-card > .ant-card-body > .sonar-card:not(:first-child) {
		border-top: 0;
	}

	td {
		white-space: normal;
	}

	.ant-picker {
		margin-left: size.$size-8;
	}

	.ant-table-row-expand-icon-cell {
		padding-right: 0;
	}

	.ant-table-expand-icon-col {
		width: size.$size-24;
	}

	.timeline-date-picker {
		display: inline;
		justify-content: flex-end;
	}

	.ant-table-expanded-row {
		background: palette.$gray-2;
		margin: 0;

		.ant-table-cell {
			padding: 0;
		}

		.ant-card {
			margin: 0;

			&.change {
				background: palette.$gray-2;
			}
		}

		.diff {
			margin-bottom: size.$size-16;
		}

		code {
			overflow-x: auto;
		}

		.label {
			padding-right: size.$size-24;
			text-align: left;
			font-weight: text.$semibold;
			color: palette.$gray-7;
		}

		.value {
			@include mixins.text-semibold(14);
		}
	}

	.issue {
		white-space: normal;
	}

	.version {
		font-weight: text.$regular;
	}
}
