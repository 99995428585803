.scope-type-subsection {
    > .ant-collapse-item {
        > .ant-collapse-header {
            padding-left: 0;
            padding-bottom: 0;
        }

        > .ant-collapse-content {
            > .ant-collapse-content-box {
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 12px;
            }
        }
    }

    .data-dictionary-table {
        .ant-table-header {
            padding-bottom: 0 !important;
        }
    }
}
