@use 'css/vars/colors';
@use 'css/vars/palette';
@use 'css/vars/size';
@use 'css/mixins';

.periscope-card {
	display: flex;
	flex-direction: column;
	color: palette.$gray-1;
	flex: 1;
	overflow: hidden;
	height: 100%;
	width: 0px;

	.hidden {
		display: none;
	}

	> .periscope-header-wrapper {
		@include mixins.text-semibold(16);
		border-top-left-radius: size.$size-4;
		border-top-right-radius: size.$size-4;
		color: rgba(palette.$gray-1, 0.85);
		display: grid;
		grid-auto-flow: column;
  		grid-auto-columns: 1fr;
		align-items: center;
		justify-content: space-between;
		gap: size.$size-8;
		height: 48px;
		min-height: 48px;
		padding: 0px size.$size-16;

		a {
			color: palette.$gray-1;
		}

		> * {
			display: flex;
		}

		.periscope-type-subtype-labels{
			overflow: hidden;
			.periscope-type-label{
				@include mixins.text-semibold(16);
				display: flex;
				align-items: center;

			}
			.periscope-subtype-label{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: flex;
				align-items: flex-end;
			}
		}

		.source-label {
			font-size: size.$size-16;
			white-space: nowrap;
			user-select: none;
			justify-content: center;
			gap: size.$size-8;
		}

		.periscope-close {
			flex-direction: row-reverse;
			.ant-btn{
				color: rgba(palette.$gray-1, 0.7);
			}

				:hover {
					color: palette.$gray-1;
				}

				.ant-btn-icon {
					display: flex;
					flex-direction: row-reverse;
					padding-right: 4px;
				}
		}
	}

	.periscope-content {
		background-color: palette.$gray-3;
		border-radius: size.$size-4;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		padding: 0;
		-webkit-box-shadow: none;

		.periscope-content-tabless {
			color: colors.$default-black;
			overflow-y: auto;
			padding: 0.5em;
		}

		.ant-tabs {
			&.periscope-content-without-header {
				height: calc(100% - #{size.$size-8});
			}

			.ant-tabs-content {
				height: 100%;
			}
		}

		.ant-tabs-nav {
			background-color: palette.$gray-1;
		}

		.field-information {
			padding-bottom: size.$size-24;

			.ant-descriptions-item-label {
				@include mixins.text-semibold(12);
				margin-left: size.$size-16;
			}

			.ant-descriptions-item {
				padding-bottom: size.$size-2;
			}

			.ant-descriptions-item-content {
				align-items: center;
			}
		}

		.value-search {
			margin-bottom: size.$size-16;
			margin-top: size.$size-4;
			width: 320px;
		}
	}
}
