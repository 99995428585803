@use 'css/vars/size';
@use 'css/mixins';

.simple-pagination {
	padding: size.$size-8 size.$size-4 size.$size-4 size.$size-4;
	text-align: right;

	.ant-btn {
		margin-right: size.$size-8;
	}

	.ant-btn-icon-only {
		vertical-align: -1px;

		> * {
			@include mixins.text-regular(12);
		}
	}

	.ant-select {
		margin-left: size.$size-8;
	}
}
