@use 'css/vars/size';
@use 'css/mixins';

.gateway-card {
    width: 500px;

    p {
        @include mixins.text-regular(20);
    }
}
