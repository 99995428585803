@use 'css/vars/palette';
@use 'css/vars/size';

.select-monitor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.hide-first {
    > .ant-space-item:first-child {
        display: none;
    }
}

.menu-group {
    display: flex;    
}

.menu-group-item {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.template-group-item {
    height: auto;
    min-width: 320px;
    max-width: 640px;
    padding: 0;
}
