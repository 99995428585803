@use 'css/vars/size';

.vertical-column-view {
	.vertical-column-view-content {
		display: flex;
		flex-direction: column;
		row-gap: size.$size-8;

		> .ant-row {
			row-gap: size.$size-8;
		}
	}

	.sonar-card .ant-card-head {
		border-bottom: none;
	}
}
